<template>
  <md-step
    id="postreport"
    md-label="Post Report"
    :md-done.sync="stepper.postreport"
  >
    <div class="md-layout md-gutter md-alignment-center-center">
      <div class="md-layout-item md-size-100">
        <h2>
          <md-icon class="md-layout-item md-size-5x">
            {{ getProvisionStatusIcon }}
          </md-icon>
          {{ getProvisionPostReportTitle }}
        </h2>
      </div>
      <div class="md-layout-item">
        <h5>Here's some next steps you can take:</h5>
        <div v-if="!postReportDetails.success">
          <ul>
            <li class="pad-bottom">
              <span class="md-subheading">
                Check Deployment Logs in Octopus
              </span>
              <div class="md-caption">
                Take a look to see if any error was reported by Octopus. Often
                times this will display what went wrong and where.

                <div
                  v-for="postReportStage in postReportDetails.stages"
                  :key="postReportStage.stage"
                >
                  <h6>
                    {{ postReportStage.stage }} ({{ postReportStage.region }})
                  </h6>
                  <ul>
                    <li
                      v-for="(
                        uri, key
                      ) in postReportStage.octopusDeploymentUris"
                      :key="uri"
                    >
                      <a :href="uri" target="_blank">{{ key }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="pad-bottom">
              <span class="md-subheading">Check Seq Logs</span>
              <div class="md-caption">
                This provisioning process is logged to Seq which provides more
                debug information regarding the entire orchestration.
                Recommended filter to apply:
                <code>Application = 'Provision App'</code>
              </div>
            </li>
            <li class="pad-bottom">
              <a class="md-subheading" @click="provisionTenant" href="#">
                Retry Provision
              </a>
              <div class="md-caption">
                After you have investigated and fixed any external issues
                reported, you can retry provisioning the tenant.
              </div>
            </li>
          </ul>
        </div>
        <ul v-if="postReportDetails.success">
          <li class="pad-bottom">
            <span class="md-subheading">Setup Check Profile</span>
            <div class="md-caption">
              Check Profiles enable a set of Checks to run during a detection
              call. Check routing is required when multiple check profiles are
              enabled.
            </div>
            <div>
              <md-button
                v-for="(sr, i) in postReportDetails.stages"
                :key="i"
                class="md-info md-sm"
                style="margin-right: 10px"
                :href="getPostReportSetupCheckProfileUri(sr.controlCenterUri)"
                target="_blank"
              >
                {{ sr.region }}-{{ sr.stage }}
              </md-button>
            </div>
          </li>
          <li class="pad-bottom">
            <span class="md-subheading">Setup Standard Mappings</span>
            <br />
            <div class="md-caption">
              Standard Mappings allow customer specific values to be mapped to
              FRISS values that can be understood our products.
            </div>
            <div>
              <md-button
                v-for="(sr, i) in postReportDetails.stages"
                :key="i"
                class="md-info md-sm"
                style="margin-right: 10px"
                :href="
                  getPostReportSetupStandardMappingsUri(sr.controlCenterUri)
                "
                target="_blank"
              >
                {{ sr.region }}-{{ sr.stage }}
              </md-button>
            </div>
          </li>
          <li class="pad-bottom">
            <span class="md-subheading">Import Users</span>
            <div class="md-caption">
              Easily import a set of users by uploading a .CSV (Comma Separated
              Separated Value) file.
            </div>
            <div>
              <md-button
                v-for="(sr, i) in postReportDetails.stages"
                :key="i"
                class="md-info md-sm"
                style="margin-right: 10px"
                :href="getPostReportImportUsersUrl(sr.controlCenterUri)"
                target="_blank"
              >
                {{ sr.region }}-{{ sr.stage }}
              </md-button>
            </div>
          </li>
          <li
            class="pad-bottom"
            v-if="
              !tenant.octopusDeployments[OCTOPUS_PROJECTS.FCM].skip ||
              !tenant.octopusDeployments[OCTOPUS_PROJECTS.TENANT_WEB].skip
            "
          >
            <span class="md-subheading">Create WSA Account</span>
            <div class="md-caption">
              A WSA Account is required for authenticating against SOAP-based
              (Legacy) Detection API.
            </div>
            <div>
              <md-button
                v-for="(sr, i) in postReportDetails.stages"
                :key="i"
                class="md-info md-sm"
                style="margin-right: 10px"
                :href="getPostReportWsaAccountUrl(sr.caseManagementUri)"
                target="_blank"
              >
                {{ sr.region }}-{{ sr.stage }}
              </md-button>
            </div>
          </li>
          <li
            v-if="
              !tenant.octopusDeployments[OCTOPUS_PROJECTS.FCM].skip ||
              !tenant.octopusDeployments[OCTOPUS_PROJECTS.TENANT_WEB].skip
            "
          >
            <span class="md-subheading">Setup or Import KB</span>
            <div class="md-caption">
              Detection requires a Knowledge Base to operate correctly. This
              dictates the indicators and data sources to execute.
            </div>
            <div>
              <md-button
                v-for="(sr, i) in postReportDetails.stages"
                :key="i"
                class="md-info md-sm"
                style="margin-right: 10px"
                :href="getPostReportImportKbUrl(sr.kbManagementUri)"
                target="_blank"
              >
                {{ sr.region }}-{{ sr.stage }}
              </md-button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </md-step>
</template>

<script>
import { OCTOPUS_PROJECTS } from "@/components/ProvisionsPlugin/ProvisionConstants";

export default {
  name: "ReportStep",
  props: {
    stepper: {
      type: Object,
      required: true
    },
    provisioning: {
      type: Object,
      required: true
    },
    tenant: {
      type: Object,
      required: true
    },
    postReportDetails: {
      type: Object,
      required: true
    }
  },
  computed: {
    OCTOPUS_PROJECTS() {
      return OCTOPUS_PROJECTS;
    },
    getProvisionStatusIcon() {
      if (this.provisioning.success) return "check";
      else return "error_outline";
    },
    getProvisionPostReportTitle() {
      return this.provisioning.success
        ? "Tenant successfully provisioned!"
        : "Something went wrong while provisioning the tenant.";
    }
  },
  methods: {
    provisionTenant() {
      this.$emit("provision-tenant");
    },
    getPostReportSetupCheckProfileUri(baseUrl) {
      return `${baseUrl}/Configuration/CheckProfiles`;
    },
    getPostReportSetupStandardMappingsUri(baseUrl) {
      return `${baseUrl}/StandardMappings`;
    },
    getPostReportImportUsersUrl(baseUrl) {
      return `${baseUrl}/Users/ImportUsers`;
    },
    getPostReportWsaAccountUrl(baseUrl) {
      return `${baseUrl}/Friss.TenantWeb/integrations`;
    },
    getPostReportImportKbUrl(baseUrl) {
      return `${baseUrl}/Friss.Suite/CaseManagement/ImportExport.aspx`;
    },
    getStageValue(region, stage) {
      const suffix = stage.cloudBased ? "-az" : "-saas";
      return `${region.regionCode}-${stage.value}${suffix}`;
    }
  }
};
</script>

<style scoped></style>
