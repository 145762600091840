<template>
  <md-step
    id="tenant"
    md-label="Who?"
    :md-done.sync="stepper.tenant"
    :md-editable="
      !provisioning.started || (provisioning.started && provisioning.ended)
    "
  >
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100 md-size-100">
        <md-field>
          <label>Select the tenant</label>
          <md-select name="tenant" id="tenant" @md-selected="onSelectTenant">
            <md-option
              v-for="(item, index) in availableTenants"
              :key="item.tenantCode"
              :value="index"
            >
              {{ item.name }}
            </md-option>
          </md-select>
        </md-field>
      </div>
    </div>
    <div class="md-layout md-gutter md-alignment-center-center">
      <md-button
        class="md-primary"
        @click="nextStep"
        :disabled="this.tenant.name === null"
      >
        Next
      </md-button>
    </div>
  </md-step>
</template>

<script>
export default {
  name: "TenantStep",
  props: {
    provisioning: {
      type: Object,
      required: true
    },
    stepper: {
      type: Object,
      required: true
    },
    tenant: {
      type: Object,
      required: true
    },
    availableTenants: {
      type: Array,
      required: true
    }
  },
  methods: {
    onSelectTenant(index) {
      this.$emit("select-tenant", index);
    },
    nextStep() {
      this.$emit("next-step", "tenant", "region");
    }
  }
};
</script>

<style scoped></style>
