<template>
  <div class="md-layout md-gutter md-alignment-center-center">
    <running v-if="isRunning" :status="provisionStatus" />
    <failed v-if="isFailed" :status="provisionStatus" />
    <success v-if="isCompleted" :status="provisionStatus" />
    <pending v-if="!!!provisionStatus" />
  </div>
</template>

<script>
import Running from "@/components/ProvisionStatus/Status/Running";
import Failed from "@/components/ProvisionStatus/Status/Failed";
import Success from "@/components/ProvisionStatus/Status/Success";
import Pending from "@/components/ProvisionStatus/Status/Pending";

export default {
  name: "ProvisionStatus",
  components: { Pending, Running, Failed, Success },
  props: {
    provisionResponseMetadata: Object
  },
  data() {
    return {
      provisionStatus: null,
      pollStatusTimerId: null
    };
  },
  created() {
    this.pollStatus();
  },
  computed: {
    isFailed() {
      return (
        !!this.provisionStatus &&
        this.provisionStatus?.runtimeStatus &&
        (this.provisionStatus?.runtimeStatus.toLowerCase() === "failed" ||
          this.provisionStatus?.runtimeStatus.toLowerCase() === "terminated")
      );
    },
    isCompleted() {
      return (
        !!this.provisionStatus &&
        this.provisionStatus?.runtimeStatus &&
        this.provisionStatus?.runtimeStatus.toLowerCase() === "completed"
      );
    },
    isRunning() {
      return (
        !!this.provisionStatus &&
        this.provisionStatus?.runtimeStatus.toLowerCase() !== "failed" &&
        this.provisionStatus?.runtimeStatus.toLowerCase() !== "completed" &&
        this.provisionStatus?.runtimeStatus.toLowerCase() !== "terminated"
      );
    }
  },
  methods: {
    pollStatus() {
      const intervalTimeInMs = 2500;
      this.pollStatusTimerId = setInterval(async () => {
        const statusResponse = await this.$http.get(
          this.provisionResponseMetadata.statusQueryGetUri
        );

        this.provisionStatus = statusResponse.data;

        if (this.isCompleted || this.isFailed) {
          clearInterval(this.pollStatusTimerId);
        }
      }, intervalTimeInMs);
    }
  },
  beforeDestroy() {
    clearInterval(this.pollStatusTimerId);
  }
};
</script>

<style scoped></style>
