var render = function render(){var _vm=this,_c=_vm._self._c;return _c('md-table-row',[_c('md-table-cell',[_vm._v(" "+_vm._s(_vm.tenant.name)+" ")]),_c('md-table-cell',{attrs:{"width":"10%"}},[(_vm.userCanWrite)?_c('md-button',{staticClass:"md-just-icon md-simple md-primary",attrs:{"disabled":_vm.isProvisionInProgress || _vm.isDeprovisionInProgress},on:{"click":_vm.edit}},[_c('md-icon',[_vm._v("edit")]),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Edit")])],1):_vm._e(),(_vm.isProvisionInProgress)?_c('div',[_c('md-tooltip',{attrs:{"md-direction":"top"}},[_c('div',_vm._l((_vm.provisionsInProgress),function(provision,provIndex){return _c('div',{key:provision.provisionId + provIndex},[_vm._v(" Provision is "),_c('strong',[_vm._v(_vm._s(provision.state))]),_vm._v(" for "),_vm._l((provision.environments),function(env,envIndex){return _c('div',{key:env + envIndex},[_c('md-chip',{staticClass:"stages-chip"},[_vm._v(" "+_vm._s(env.stage)+"-"+_vm._s(env.region)+" ")])],1)})],2)}),0)]),_c('md-button',{staticClass:"md-just-icon md-simple md-info in-progress",attrs:{"disabled":""}},[_c('md-icon',[_vm._v("cloud_upload")])],1),_c('div',{staticClass:"in-progress-button"},[_c('md-progress-bar',{attrs:{"md-mode":"indeterminate"}})],1)],1):_vm._e(),(
        _vm.userCanWriteProvision && !_vm.isProvisionInProgress && _vm.tenant.cloudConsent
      )?_c('md-button',{staticClass:"md-just-icon md-simple md-info",attrs:{"disabled":_vm.isDeprovisionInProgress || !_vm.isProvisionAppApiAvailable},on:{"click":_vm.deploy}},[_c('md-icon',[_vm._v("cloud_upload")]),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Provision")])],1):_vm._e(),(_vm.tenant.cloudConsent && _vm.userCanWriteProvision)?_c('md-button',{staticClass:"md-just-icon md-simple md-info",attrs:{"disabled":_vm.isProvisionInProgress ||
        _vm.isDeprovisionInProgress ||
        !_vm.isProvisionAppApiAvailable},on:{"click":_vm.onAllowlisting}},[_c('md-icon',[_vm._v("rule")]),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("IP Allowlisting")])],1):_vm._e(),_c('md-menu',{attrs:{"md-direction":"bottom-end","md-align-trigger":""}},[_c('md-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.tenant.cloudConsent && _vm.userCanWriteProvision && !_vm.isDevelopmentStage),expression:"tenant.cloudConsent && userCanWriteProvision && !isDevelopmentStage"}],staticClass:"md-just-icon md-simple md-info",attrs:{"md-menu-trigger":"","disabled":_vm.isProvisionInProgress ||
          _vm.isDeprovisionInProgress ||
          !_vm.isProvisionAppApiAvailable}},[_c('md-icon',[_vm._v("token")]),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Integrations")])],1),_c('md-menu-content',[_c('md-menu-item',{on:{"click":_vm.onFeiIntegration}},[_vm._v("Enterprise Investigations")])],1)],1),(_vm.isDeprovisionInProgress)?_c('div',[_c('md-tooltip',{attrs:{"md-direction":"top"}},[_c('div',_vm._l((_vm.deprovisionsInProgress),function(deprovision,depIndex){return _c('div',{key:deprovision.provisionId + depIndex},[_vm._v(" Deprovision is "),_c('strong',[_vm._v(_vm._s(deprovision.state))]),_vm._v(" for "),_vm._l((deprovision.environments),function(env,envIndex){return _c('div',{key:env + envIndex},[_c('md-chip',{staticClass:"stages-chip"},[_vm._v(" "+_vm._s(env.stage)+"-"+_vm._s(env.region)+" ")])],1)})],2)}),0)]),_c('md-button',{staticClass:"md-just-icon md-simple md-danger in-progress",attrs:{"disabled":""}},[_c('md-icon',[_vm._v("cloud_download")])],1),_c('div',{staticClass:"in-progress-button"},[_c('md-progress-bar',{attrs:{"md-mode":"indeterminate"}})],1)],1):_vm._e(),(
        _vm.userCanDeleteProvision &&
        _vm.tenantHasProvision &&
        !_vm.isDeprovisionInProgress
      )?_c('md-button',{staticClass:"md-just-icon md-simple md-danger",attrs:{"disabled":_vm.isProvisionInProgress || !_vm.isProvisionAppApiAvailable},on:{"click":_vm.onDeprovision}},[_c('md-icon',[_vm._v("cloud_download")]),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Deprovision")])],1):_vm._e(),(_vm.userCanDeleteProvision && !_vm.tenantHasProvision)?_c('md-button',{staticClass:"md-just-icon md-simple md-danger",attrs:{"disabled":_vm.isProvisionInProgress || !_vm.isProvisionAppApiAvailable},on:{"click":_vm.onDelete}},[_c('md-icon',[_vm._v("delete")]),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Delete")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }