// =========================================================
// * Vue Material Dashboard - v1.4.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-dashboard/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import AsyncComputed from "vue-async-computed";
import VueRouter from "vue-router";
import App from "./App";
import axios from "axios";
import moment from "moment";
import { default as Adal, AxiosAuthHttp } from "vue-adal";

// router setup
import routes from "./routes/routes";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import ProvisionHub from "./provision-hub";
import Store from "@/components/Tenants/store";

// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

import "./assets/scss/general.scss";

// configure router
const router = new VueRouter({
  routes,
  linkExactActiveClass: "nav-item active"
});

const featureFlagApiBaseUrl = window.VUE_APP_FEATURE_FLAG_API_BASE_URL;
const provisionApiBaseUrl = window.VUE_APP_PROVISION_API_BASE_URL;
const redirectUri = window.VUE_APP_REDIRECT_URI;
const tenantId = window.VUE_APP_TENANT_ID;
const clientId = window.VUE_APP_CLIENT_ID;
const writeGroupId = window.VUE_APP_WRITE_GROUP_ID;
const readGroupId = window.VUE_APP_READ_GROUP_ID;
const provisionWriteGroupId = window.VUE_APP_PROVISION_WRITE_GROUP_ID;
const provisionDeleteGroupId = window.VUE_APP_PROVISION_DELETE_GROUP_ID;
const apiId = window.VUE_APP_API_ID;
const scopesSplit = window.VUE_APP_API_SCOPES.split(" ");
const scopes = scopesSplit.map(m => `${apiId}/${m}`);
const graphApiBase = `https://graph.microsoft.com/v1.0`;
const graphApiResource = "00000003-0000-0000-c000-000000000000";

Vue.use(AsyncComputed);
Vue.use(ProvisionHub);

Vue.use(Adal, {
  config: {
    tenant: tenantId,
    clientId: clientId,
    redirectUri: redirectUri,
    cacheLocation: "localStorage"
  },
  requireAuthOnInitialize: true,
  router: router
});

// wtf Vue Material....
Vue.config.errorHandler = (err, vm, info) => {
  if (process.env.NODE_ENV !== "production") {
    // Show any error but this one
    if (err.message !== "Cannot read property 'badInput' of undefined") {
    }
  }
};

Vue.use({
  install(vue, opts = {}) {
    Vue.prototype.startSignalR();

    vue.prototype.$graphApi = AxiosAuthHttp.createNewClient({
      axios: axios,
      resourceId: graphApiResource,
      router: router,
      baseUrl: graphApiBase,
      onTokenSuccess(http, context, token) {
        if (context.user) {
          http.defaults.baseURL = `${graphApiBase}/${context.user.profile.tid}`;
        }
      }
    });

    vue.prototype.$featureFlagApi = AxiosAuthHttp.createNewClient({
      axios: axios,
      resourceId: apiId,
      router: router,
      scopes: scopes,
      baseUrl: `${featureFlagApiBaseUrl}/api/v1`,

      onTokenSuccess(http, token) {
        if (token) {
          http.defaults.baseURL = `${featureFlagApiBaseUrl}/api/v1`;
        }
      }
    });
    vue.prototype.$featureFlagApi.interceptors.response.use(
      response => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202 ||
          response.status === 204
        ) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },
      error => {
        if (error.response.status) {
          switch (error.response.status) {
            case 403:
              router.push({ name: "Unauthorized" });
              break;
          }
          return Promise.reject(error.response);
        }
      }
    );

    vue.prototype.$http = AxiosAuthHttp.createNewClient({
      axios: axios,
      resourceId: apiId,
      router: router,
      scopes: scopes,
      baseUrl: "",

      onTokenSuccess(http, token) {
        if (token) {
          http.defaults.baseURL = "";
        }
      }
    });
    vue.prototype.$http.interceptors.response.use(
      response => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202 ||
          response.status === 204
        ) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },
      error => {
        if (error.response.status) {
          switch (error.response.status) {
            case 403:
              router.push({ name: "Unauthorized" });
              break;
          }
          return Promise.reject(error.response);
        }
      }
    );

    vue.prototype.$provisionApi = AxiosAuthHttp.createNewClient({
      axios: axios,
      resourceId: apiId,
      router: router,
      scopes: scopes,
      baseUrl: `${provisionApiBaseUrl}/api`,

      onTokenSuccess(http, token) {
        if (token) {
          http.defaults.baseURL = `${provisionApiBaseUrl}/api`;
        }
      }
    });
    vue.prototype.$provisionApi.interceptors.response.use(
      response => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202 ||
          response.status === 204
        ) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },
      error => {
        if (error.response.status) {
          switch (error.response.status) {
            case 403:
              router.push({ name: "Unauthorized" });
              break;
          }
          return Promise.reject(error.response);
        }
      }
    );
  }
});

Vue.mixin({
  methods: {
    checkPermission: async function (groupId) {
      let groups = await this.$graphApi.post(`/me/getMemberGroups`, {
        securityEnabledOnly: true
      });
      let res = groups.data.value.some(c => c === groupId);
      return res;
    },
    hasCurrentUserWritePermission: async function () {
      return await this.checkPermission(writeGroupId);
    },
    hasCurrentUserReadPermission: async function () {
      return await this.checkPermission(readGroupId);
    },
    hasCurrentUserProvisionWritePermission: async function () {
      return await this.checkPermission(provisionWriteGroupId);
    },
    hasCurrentUserProvisionDeletePermission: async function () {
      return await this.checkPermission(provisionDeleteGroupId);
    }
  }
});

Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);
Vue.use(VueFilterDateFormat);
Vue.use(Store);

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("MMMM Do YYYY, h:mm a");
  }
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: h => h(App),
  router,
  created() {
    if (this.$adal.isAuthenticated()) {
      this.$adal.signIn();
    }
  }
});
