<template>
  <div class="md-layout-item text-center">
    <h5 class="md-layout-item">Provisioning Tenant...</h5>
    <md-progress-spinner
      :md-diameter="45"
      :md-stroke="6"
      md-mode="indeterminate"
    />
    <br />
    <h6 class="md-layout-item">
      {{ status.customStatus || "Starting..." }}
    </h6>
  </div>
</template>

<script>
export default {
  name: "Running",
  props: {
    status: Object
  }
};
</script>

<style scoped></style>
