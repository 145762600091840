<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Advance Tenant Provisioning</h4>
            <p class="category">
              Start to provision a new tenant.
            </p>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-100">
                <div class="md-title">Warning, this page is being sunsetted!</div>
                <div class="md-subhead">It is highly recommended to visit the Tenants page and use the Express
                  Provisioning as this page will no longer be available in the near future. To request any missing 
                  features in Express Provisioning, or to have a discussion on why this page should not be sunsetted, 
                  please contact Team Lima via Microsoft Teams.</div>
                <provisions-wizard />
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import ProvisionsWizard from "../components/ProvisionsPlugin/ProvisionsWizard.vue";
export default {
  data() {
    return {
      showDialog: false
    };
  },
  components: {
    ProvisionsWizard
  }
};
</script>