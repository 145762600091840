<template>
  <ValidationObserver ref="feiForm" v-slot="{ invalid }">
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" :md-close-on-esc="false">
      <md-dialog-title align="center">
        Enterprise Investigations Integration for
        <strong>{{ tenant.name }}</strong>
      </md-dialog-title>
      <md-dialog-content class="md-scrollbar">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            Before you begin, please read over the following points:
            <ul>
              <li>
                Tenant must already have Enterprise Investigation setup and working.
              </li>
              <li>
                Tenant must be running on FSC.
              </li>
              <li>
                The Event Router & GET Endpoint Client Id, Client Secret and URLs values of Enterprise
                Investigations.<br /><b>Ask Team Polo for these values</b>
              </li>
            </ul>
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <md-field>
                    <label>Select a stage</label>
                    <md-select name="stage" id="stage" v-model="tenant.integrations.region"
                      @md-selected="assignGeneratedValues()">
                      <md-optgroup v-for="region in regionsAndStages" :key="region.name" :label="region.name">
                        <md-option v-for="stage in region.stages" :key="stage.name"
                          :value="getStageValue(region, stage)">
                          {{ stage.name }} ({{
                            region.regionCode.toUpperCase()
                          }})
                        </md-option>
                      </md-optgroup>
                    </md-select>
                    <span class="md-helper-text">
                      Changing this value will <b>auto-generate</b> the URLs.&nbsp;&nbsp;&nbsp;<b><u>Manually
                          change</u></b> these generated values if necessary!
                    </span>
                    <span class="md-error">
                      {{ errors[0] }}
                    </span>
                  </md-field>
                </ValidationProvider>
                <ValidationProvider rules="required|urlregex" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>Authentication URL</label>
                    <md-input v-model="tenant.integrations.fei.authurl"></md-input>

                    <span class="md-error">
                      {{ errors[0] }}
                    </span>
                  </md-field>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>GET Endpoint Client Id</label>
                    <md-input v-model="tenant.integrations.fei.clientid"></md-input>
                    <span class="md-error">
                      {{ errors[0] }}
                    </span>
                  </md-field>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>GET Endpoint Client Secret</label>
                    <md-input v-model="tenant.integrations.fei.clientsecret" type="password"></md-input>
                    <span class="md-error">
                      {{ errors[0] }}
                    </span>
                  </md-field>
                </ValidationProvider>
                <h5 style="margin-bottom: 0px; margin-top: 35px;"><b>Event Router Integration</b></h5>
                <ValidationProvider rules="required|urlregex" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>Delivery URL</label>
                    <md-input v-model="tenant.integrations.fei.eventrouter.deliveryurl"></md-input>
                    <span class="md-error">
                      {{ errors[0] }}
                    </span>
                  </md-field>
                </ValidationProvider>
                <ValidationProvider rules="required|deliveryurlregex" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>Delivery Authentication URL</label>
                    <md-input v-model="tenant.integrations.fei.eventrouter.deliveryauthurl"></md-input>
                    <span class="md-helper-text">
                      URL <b>required to end</b> with <code>/oauth2/token</code> or <code>/connect/token</code>
                    </span>
                    <span class="md-error">
                      {{ errors[0] }}
                    </span>
                  </md-field>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>Event Router Client Id</label>
                    <md-input v-model="tenant.integrations.fei.eventrouter.clientid" type="password"></md-input>
                    <span class="md-error">
                      {{ errors[0] }}
                    </span>
                  </md-field>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>Event Router Client Secret</label>
                    <md-input v-model="tenant.integrations.fei.eventrouter.clientsecret" type="password"></md-input>
                    <span class="md-error">
                      {{ errors[0] }}
                    </span>
                  </md-field>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>Scope</label>
                    <md-input v-model="tenant.integrations.fei.eventrouter.scope"></md-input>
                    <span class="md-error">
                      {{ errors[0] }}
                    </span>
                  </md-field>
                </ValidationProvider>
                <span>
                  <center><b>VERIFY ALL VALUES BEFORE SAVE & RUN</b></center>
                </span>
              </div>
            </div>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button @click="onClose">Close</md-button>
        <md-button class="md-primary" @click="onSave" :disabled="invalid">
          Save & Run
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required"
});
extend('urlregex', {
  validate(value, { }) {
    return /^(https?:\/\/)([\da-z\.-]+\.[a-z]{2,6})(\/[\w\.-]*)*\/?$/i.test(value);
  },
  params: ['pattern'],
  message: 'This field must be a URL format including the protocol (http:// or https://)'
});
extend('deliveryurlregex', {
  validate(value, { }) {
    return /^(https?:\/\/)([\da-z\.-]+\.[a-z]{2,6})(\/[\w\.-]*)*(\/oauth2\/token|\/connect\/token)\/?$/i.test(value);
  },
  params: ['pattern'],
  message: 'This field must be a URL format including the protocol (http:// or https://) and end with /oauth2/token or /connect/token'
});

const provisionApiCode = window.VUE_APP_PROVISION_API_CODE;

export default {
  name: "FeiIntegrationsDialog",
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      tenant: this.$tenantStore.state.currentTenant,
      regionsAndStages: []
    };
  },
  computed: {
    showDialog() {
      return this.$tenantStore.state.showFeiDialog;
    }
  },
  async created() {
    const regionsResult = await this.$featureFlagApi.get(
      "/regions/integrations"
    );
    const regions = regionsResult.data;
    regions.map(c => {
      c.errors = { name: [], description: [] };
    });
    this.regionsAndStages = regions;
  },
  methods: {
    assignGeneratedValues() {
      const { region, stage } = this.parseRegionAndStage();
      const domainRegion = this.getDomainRegion(region);
      const subdomainStage = this.getSubdomainStage(stage);
      const regionPart = domainRegion ? `.${domainRegion}` : '';
      this.tenant.integrations.fei.eventrouter.deliveryurl = this.buildUrl(subdomainStage, regionPart);

      this.tenant.integrations.fei.authurl = this.buildAuthurl()
      this.tenant.integrations.fei.eventrouter.deliveryauthurl = `${this.buildAuthurl()}/oauth2/token`

      const scopeStage = this.getScopeStage(stage);
      this.tenant.integrations.fei.eventrouter.scope = this.buildScope(scopeStage);
    },
    parseRegionAndStage() {
      const [region, stage] = this.tenant.integrations.region.split('-');
      return { region, stage };
    },
    getDomainRegion(region) {
      const regionMap = {
        ca: 'cn',
        us: 'us',
        nl: '',
      };
      return regionMap[region] ?? region;
    },
    getSubdomainStage(stage) {
      const stageMap = {
        acc: 'uat',
        spa: 'spa',
        prd: ''
      };
      return stageMap[stage] ?? '';
    },
    getScopeStage(stage) {
      const stageMap = {
        acc: 'uat',
        spa: 'spa',
        prd: 'prod'
      };
      return stageMap[stage] ?? '';
    },
    buildAuthurl() {
      return `https://ei-${this.tenant.tenantCode}.auth.eu-west-2.amazoncognito.com`
    },
    buildUrl(subdomainStage, regionPart) {
      return `https://${this.tenant.tenantCode}${subdomainStage}-events.siu.frisscloud.com`;
    },
    buildScope(scopeStage) {
      return `ei-resource-server/${scopeStage}`
    },
    getStageValue(region, stage) {
      return `${region.regionCode}-${stage.value}`;
    },
    onClose() {
      this.$tenantStore.closeFeiDialog();
    },
    async onSave() {
      let [region, stage] =
        this.tenant.integrations.region.split("-");
      if (region === "ca") region = "cn";

      const header = {
        headers: { "requested-by": this.$adal.user.profile.email }
      };
      await this.$provisionApi
        .post(
          `/integrations/fei?code=${provisionApiCode}`,
          {
            TenantCode: this.tenant.tenantCode,
            TenantName: this.tenant.name,
            Region: region,
            Stage: stage,
            Domain: this.tenant.domain,
            TenantGroup: this.tenant.tenantGroup,
            AuthUrl: this.tenant.integrations.fei.authurl,
            ClientId: this.tenant.integrations.fei.clientid,
            ClientSecret: this.tenant.integrations.fei.clientsecret,
            EventRouter: {
              DeliveryAuthUrl: this.tenant.integrations.fei.eventrouter.deliveryauthurl,
              DeliveryUrl: this.tenant.integrations.fei.eventrouter.deliveryurl,
              ClientId: this.tenant.integrations.fei.eventrouter.clientid,
              ClientSecret: this.tenant.integrations.fei.eventrouter.clientsecret,
              Scope: this.tenant.integrations.fei.eventrouter.scope
            }
          },
          header
        )
        .then(() => {
          this.$notify({
            message: "Integration with FEI started!",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "success"
          });
          this.$tenantStore.closeFeiDialog();
        })
        .catch(error => {
          let message = "Failed to start FEI Integration...";
          if (error.response) {
            message += `: ${error.response.data.errors.join(" ")}`;
          }
          this.$notify({
            message: message,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "danger"
          });
        });
    }
  }
};
</script>

<style scoped>
.md-dialog-content {
  max-width: 1024px;
}
</style>
