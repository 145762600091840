export const OCTOPUS_PROJECTS = {
  FCM: "CaseManagement - Configuration",
  TENANT_WEB: "Tenant Web",
  AUDIT: "Friss Audit",
  SIDE_BY_SIDE: "Side by Side"
};

export const STAGES_REQUIRING_USERNAME_CHECK = [
  "nl-spa",
  "nl-acc",
  "nl-prd",
  "cn-acc",
  "cn-prd",
  "us-prd",
  "us-acc"
];

export const PRODUCT_FLAG_CODES = {
  FI: "facts",
  CLOUD: "cloud",
  CLOUD_FSC: "cloud_fsc",
  NETWORKVIEWER: "networkviewer"
};

export const LEGACY_FEATURE_FLAGS = [
  {
    name: "Abz Graydon Ubo Cis monitoring cleanup",
    code: "jobkicker.job.abzgraydonubocismonitoring",
    description: "",
    defaultValue: false,
    requireCloudConsentFromTenant: false
  },
  {
    name: "Abz Graydon Ubo Non Cis monitoring cleanup",
    code: "jobkicker.job.abzgraydonubononcismonitoring",
    description: "",
    defaultValue: false,
    requireCloudConsentFromTenant: false
  },
  {
    name: "Cases cleanup",
    code: "jobkicker.job.cleanupcases",
    description: "",
    defaultValue: false,
    requireCloudConsentFromTenant: false
  },
  {
    name: "Claims cleanup",
    code: "jobkicker.job.cleanupclaims",
    description: "",
    defaultValue: false,
    requireCloudConsentFromTenant: false
  },
  {
    name: "Include Check Facts in PDF Document",
    code: "pdfcheckfacts",
    description: "",
    requireCloudConsentFromTenant: true,
    defaultValue: false
  },
  {
    name: "Cloud Detection Redirection",
    code: "clouddetectionredirection",
    description:
      "Redirect Detection Requests from Legacy Detection to Cloud Detection",
    requireCloudConsentFromTenant: true,
    defaultValue: false
  },
  {
    name: "Continuous compliancy",
    code: "jobkicker.job.continuouscompliancy",
    description: "",
    defaultValue: false,
    requireCloudConsentFromTenant: false
  },
  {
    name: "Fdb data dump",
    code: "jobkicker.job.fdbdatadump",
    description: "",
    defaultValue: false,
    requireCloudConsentFromTenant: false
  },
  {
    name: "Fde log cleanup",
    code: "jobkicker.job.cleanupfdelogs",
    description: "",
    defaultValue: false,
    requireCloudConsentFromTenant: false
  },
  {
    name: "Log interface cleanup",
    code: "jobkicker.job.cleanupplugins",
    description: "",
    defaultValue: false,
    requireCloudConsentFromTenant: false
  },
  {
    name: "Screening cleanup",
    code: "jobkicker.job.cleanupscreenings",
    description: "",
    defaultValue: false,
    requireCloudConsentFromTenant: false
  },
  {
    name: "Single Sign On",
    code: "sso",
    description: "",
    defaultValue: true,
    requireCloudConsentFromTenant: true
  },
  {
    name: "Stream Publishing",
    code: "streampublish",
    description: "",
    requireCloudConsentFromTenant: true,
    defaultValue: true
  }
];

export const LEGACY_PRODUCT_FLAGS = [
  {
    name: "FRISS Cloud",
    code: "cloud",
    description: "",
    requireCloudConsentFromTenant: true,
    defaultValue: true
  },
  {
    name: "FRISS Investigations",
    code: "facts",
    description: "",
    requireCloudConsentFromTenant: false,
    defaultValue: false
  },
  {
    name: "Network Viewer",
    code: "networkviewer",
    description: "",
    requireCloudConsentFromTenant: true,
    defaultValue: false
  }
];
