<template>
  <div class="md-layout md-gutter md-alignment-center-center">
    <div class="md-layout-item text-center" v-show="!hasFinished">
      <md-progress-spinner
        :md-diameter="45"
        :md-stroke="6"
        md-mode="indeterminate"
      ></md-progress-spinner>
      <br />
      <h6 class="md-layout-item">
        {{ deprovisionStatus.customStatus }}
      </h6>
    </div>

    <div
      class="md-layout-item text-center"
      v-if="deprovisionStatus.runtimeStatus.toLowerCase() === 'failed'"
    >
      <h5 class="md-layout-item">Deployment has failed!</h5>
      <h6 class="md-layout-item">
        {{ deprovisionStatus.output }}
      </h6>
    </div>

    <div
      class="md-layout-item text-center"
      v-if="deprovisionStatus.runtimeStatus.toLowerCase() === 'completed'"
    >
      <h5 class="md-layout-item">
        The deprovisioning was executed with success!
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeprovisionStatus",
  props: {
    deprovisionStatus: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasFinished() {
      return (
        this.deprovisionStatus.runtimeStatus.toLowerCase() === "failed" ||
        this.deprovisionStatus.runtimeStatus.toLowerCase() === "completed" ||
        this.deprovisionStatus.runtimeStatus.toLowerCase() === "terminated"
      );
    }
  }
};
</script>

<style scoped></style>
