<template>
  <md-step
    id="options"
    md-label="Options"
    :md-done.sync="stepper.options"
    :md-editable="
      !provisioning.started || (provisioning.started && provisioning.ended)
    "
  >
    <div class="md-layout md-gutter">
      <span class="md-layout-item md-size-100" v-if="!tenant.cloudConsent">
        <small>
          <i>
            *Some feature and product flags have been disabled because the
            customer has not given consent to send or save data in the cloud.
          </i>
        </small>
      </span>
      <div class="md-layout-item md-small-size-100 md-size-50">
        <div class="md-layout-item md-small-size-100 md-size-100">
          <h5>Product Flags</h5>
          <small>
            Switching a Product Flag
            <strong>on</strong>
            during this process will enable it across
            <strong>all selected</strong>
            stages.
          </small>
          <div v-for="item in productFlags" :key="item.code">
            <md-switch
              v-model="tenant.productFlags"
              :value="item"
              :disabled="!isAllowedToUseFeature(item, tenant)"
            >
              {{ item.name }}
            </md-switch>
          </div>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-100">
          <h5>Features Flags</h5>
          <small>
            Switching a Feature Flag
            <strong>on</strong>
            during this process will enable it across
            <strong>all selected</strong>
            stages.
            <br />
            <i>
              If you desire more control for a feature flag in a specific stage,
              stage, visit the Feature Flags page.
            </i>
          </small>
          <div v-for="item in featureFlags" :key="item.code">
            <md-switch
              v-model="tenant.featureFlags"
              :value="item"
              :disabled="!isAllowedToUseFeature(item, tenant)"
            >
              {{ item.name }}
            </md-switch>
          </div>
        </div>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-50">
        <div
          class="md-layout-item md-small-size-100 md-size-100 pad-bottom"
          v-if="hasTenantCodeExceedingWindowsLimitation()"
        >
          <h5>Windows Account Configuration</h5>
          <small>
            Attention! This tenant has a tenant code that
            <strong>exceeds 16 characters</strong>
            . Deployment in Octopus
            <strong>will fail</strong>
            due to a limitation in Windows.
            <br />
            Please set a new username that is 16 characters or less.
          </small>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <md-field :class="{ 'md-invalid': errors.length > 0 }">
              <label>Service Username</label>
              <md-input
                v-model="tenant.serviceUsername"
                maxlength="16"
                :required="hasTenantCodeExceedingWindowsLimitation()"
              ></md-input>
              <span class="md-error">
                {{ errors[0] }}
              </span>
            </md-field>
          </ValidationProvider>
          <small>
            <i>
              Preview:
              <ul
                v-for="(preview, index) in previewServiceUsernameForStages"
                :key="index"
              >
                <li>{{ preview }}</li>
              </ul>
            </i>
          </small>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-100">
          <h5>Deployment Options</h5>
          <md-switch
            v-model="tenant.octopusDeployments[OCTOPUS_PROJECTS.FCM].skip"
          >
            Skip CaseManagement - Configuration Deployment
          </md-switch>
          <div>
            <small>
              Switching this flag
              <strong
                v-if="!tenant.octopusDeployments[OCTOPUS_PROJECTS.FCM].skip"
              >
                on
              </strong>
              <strong v-else>off</strong>
              will
              <strong
                v-if="!tenant.octopusDeployments[OCTOPUS_PROJECTS.FCM].skip"
              >
                not deploy
              </strong>
              <strong v-else>deploy</strong>
              &nbsp;
              <code>CaseManagement - Configuration</code>
              project in Octopus Deploy.
              <br />
              <i>
                This is required when working with a new tenant, and in some
                cases to switch ON certain features.
              </i>
            </small>
          </div>
          <md-switch
            v-model="tenant.octopusDeployments[OCTOPUS_PROJECTS.AUDIT].skip"
          >
            Skip Friss Audit Deployment
          </md-switch>
          <div>
            <small>
              Switching this flag
              <strong
                v-if="!tenant.octopusDeployments[OCTOPUS_PROJECTS.AUDIT].skip"
              >
                on
              </strong>
              <strong v-else>off</strong>
              will
              <strong
                v-if="!tenant.octopusDeployments[OCTOPUS_PROJECTS.AUDIT].skip"
              >
                not deploy
              </strong>
              <strong v-else>deploy</strong>
              &nbsp;
              <code>Friss Audit</code>
              project in Octopus Deploy.
              <br />
              <i>
                This project is currently in Maintenance Mode (no additional
                features are planned) and should be deployed once, for a New
                Tenant.
              </i>
            </small>
          </div>
          <md-switch
            v-model="
              tenant.octopusDeployments[OCTOPUS_PROJECTS.TENANT_WEB].skip
            "
          >
            Skip Tenant Web Deployment
          </md-switch>
          <div>
            <small>
              Switching this flag
              <strong
                v-if="
                  !tenant.octopusDeployments[OCTOPUS_PROJECTS.TENANT_WEB].skip
                "
              >
                on
              </strong>
              <strong v-else>off</strong>
              will
              <strong
                v-if="
                  !tenant.octopusDeployments[OCTOPUS_PROJECTS.TENANT_WEB].skip
                "
              >
                not deploy
              </strong>
              <strong v-else>deploy</strong>
              &nbsp;
              <code>Tenant Web</code>
              project in Octopus Deploy.
              <br />
              <i>
                This project should be switched on when working with an existing
                tenant.
              </i>
            </small>
          </div>
          <md-switch
            v-model="
              tenant.octopusDeployments[OCTOPUS_PROJECTS.SIDE_BY_SIDE].skip
            "
          >
            Skip Side by Side Deployment
          </md-switch>
          <div>
            <small>
              Switching this flag
              <strong
                v-if="
                  !tenant.octopusDeployments[OCTOPUS_PROJECTS.SIDE_BY_SIDE].skip
                "
              >
                on
              </strong>
              <strong v-else>off</strong>
              will
              <strong
                v-if="
                  !tenant.octopusDeployments[OCTOPUS_PROJECTS.SIDE_BY_SIDE].skip
                "
              >
                not deploy
              </strong>
              <strong v-else>deploy</strong>
              &nbsp;
              <code>Side by Side</code>
              project in Octopus Deploy.
              <br />
              <i>
                This project should be switched on when working with an existing
                tenant.
              </i>
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="md-layout md-gutter md-alignment-center-center">
      <md-button
        class="md-primary"
        @click="nextStep"
        :disabled="
          hasTenantCodeExceedingWindowsLimitation() &&
          tenant.serviceUsername.length == 0
        "
      >
        Next
      </md-button>
    </div>
  </md-step>
</template>

<script>
import { extend, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import * as Constants from "@/components/ProvisionsPlugin/ProvisionConstants";

extend("required", {
  ...required,
  message: "This field is required"
});

export default {
  name: "OptionsStep",
  components: {
    ValidationProvider
  },
  props: {
    provisioning: {
      type: Object,
      required: true
    },
    stepper: {
      type: Object,
      required: true
    },
    tenant: {
      type: Object,
      required: true
    },
    productFlags: {
      type: Array,
      required: true
    },
    featureFlags: {
      type: Array,
      required: true
    }
  },
  methods: {
    isAllowedToUseFeature(item, tenant) {
      return item.requireCloudConsentFromTenant ? tenant.cloudConsent : true;
    },
    hasTenantCodeExceedingWindowsLimitation() {
      return (
        this.tenant.tenantCode.length > 16 &&
        this.tenant.stages.some(
          s =>
            Constants.STAGES_REQUIRING_USERNAME_CHECK.filter(o =>
              s.startsWith(o)
            ).length > 0
        )
      );
    },
    previewServiceUsernameForStages() {
      let previewUsernames = [];
      let serviceUsername = this.tenant.serviceUsername;
      if (serviceUsername.length === 0) return previewUsernames;
      this.tenant.stages
        .filter(
          s =>
            Constants.STAGES_REQUIRING_USERNAME_CHECK.filter(o =>
              s.startsWith(o)
            ).length > 0
        )
        .forEach(s => {
          let stage = s.split("-")[1];
          let preview = `${stage}_${serviceUsername}`;
          previewUsernames.push(preview);
        });
      return new Set(previewUsernames);
    },
    nextStep() {
      const willSkipAllDeployments =
        this.tenant.octopusDeployments[this.OCTOPUS_PROJECTS.FCM].skip &&
        this.tenant.octopusDeployments[this.OCTOPUS_PROJECTS.TENANT_WEB].skip &&
        this.tenant.octopusDeployments[this.OCTOPUS_PROJECTS.SIDE_BY_SIDE]
          .skip &&
        this.tenant.octopusDeployments[this.OCTOPUS_PROJECTS.AUDIT].skip;

      willSkipAllDeployments
        ? this.$emit("next-step", "options", "keepasstokeyvault")
        : this.$emit("next-step", "options", "selectrelease");
    }
  },
  computed: {
    OCTOPUS_PROJECTS() {
      return Constants.OCTOPUS_PROJECTS;
    }
  }
};
</script>

<style scoped></style>
