<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <sidebar-link to="/tenants">
        <md-icon>business</md-icon>
        <p>Tenants</p>
      </sidebar-link>
      <sidebar-link to="/provisioning" v-if="userCanWriteProvision">
        <md-icon>auto_awesome_motion</md-icon>
        <p>
          Provisioning
          <sup>Sunsetting</sup>
        </p>
      </sidebar-link>
      <sidebar-link to="/audit-logs" v-if="userCanWriteProvision">
        <md-icon>analytics</md-icon>
        <p>Audit Logs</p>
      </sidebar-link>
    </side-bar>

    <div class="main-panel">
      <notifications></notifications>

      <top-navbar></top-navbar>

      <dashboard-content></dashboard-content>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter
  },
  data() {
    return {
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg")
    };
  },
  asyncComputed: {
    async userCanWriteProvision() {
      return await this.hasCurrentUserProvisionWritePermission();
    }
  }
};
</script>
