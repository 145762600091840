import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";

const featureFlagApiBaseUrl = window.VUE_APP_FEATURE_FLAG_API_BASE_URL;

export default {
  install(Vue) {
    // use a new Vue instance as the interface for Vue components to receive/send SignalR events
    // this way every component can listen to events or send new events using this.$provisionHub
    const provisionHub = new Vue();
    Vue.prototype.$provisionHub = provisionHub;

    // Provide methods to connect/disconnect from the SignalR hub
    let connection = null;
    let startedPromise = null;
    let manuallyClosed = false;

    Vue.prototype.startSignalR = jwtToken => {
      function start() {
        startedPromise = connection.start().catch(err => {
          return new Promise((resolve, reject) =>
            setTimeout(() => start().then(resolve).catch(reject), 5000)
          );
        });
        return startedPromise;
      }

      if (connection == null) {
        connection = new HubConnectionBuilder()
          .withUrl(
            `${featureFlagApiBaseUrl}/provisionhub`,
            jwtToken ? { accessTokenFactory: () => jwtToken } : null
          )
          .configureLogging(LogLevel.Error)
          .build();
        // Forward hub events through the event, so we can listen for them in the Vue components
        connection.on("SendStatusUpdate", (instanceId, message) => {
          provisionHub.$emit("receive-status-update", { instanceId, message });
        });

        connection.on("ProvisionEnded", result => {
          provisionHub.$emit("provision-ended", { result });
        });

        connection.onclose(() => {
          if (!manuallyClosed) start();
        });
        // Start everything
        manuallyClosed = false;
        start();
      }
    };

    Vue.prototype.stopSignalR = () => {
      if (!startedPromise) return;

      manuallyClosed = true;
      return startedPromise
        .then(() => connection.stop())
        .then(() => {
          startedPromise = null;
        });
    };

    // Provide methods for components to send messages back to server
    // Make sure no invocation happens until the connection is established
    provisionHub.provisionStarted = instanceId => {
      if (!startedPromise) return;

      return startedPromise.then(() =>
        connection.invoke("ProvisionStarted", instanceId)
      );
    };

    provisionHub.provisionEnded = instanceId => {
      if (!startedPromise) return;

      return startedPromise.then(() =>
        connection.invoke("ProvisionEnded", instanceId)
      );
    };
  }
};
