<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-content>
            <md-table v-model="audits" :table-header-color="tableHeaderColor">
              <md-table-empty-state
                md-label="No entries found"
                md-description="`No audit logs found in the database. Try doing something in`"
              ></md-table-empty-state>
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Tenant Code">
                  {{ item.tenantCode }}
                </md-table-cell>
                <md-table-cell md-label="Event">
                  {{ item.event }}
                </md-table-cell>
                <md-table-cell md-label="Timestamp">
                  {{ item.timestamp | formatDate }}
                </md-table-cell>
                <md-table-cell md-label="JSON Request" style="width: 55%">
                  <code>{{ item.request }}</code>
                </md-table-cell>
                <md-table-cell md-label="Requested By">
                  {{ item.requestedBy }}
                </md-table-cell>
              </md-table-row>
            </md-table>
            <md-progress-bar
              v-if="isLoading"
              md-mode="indeterminate"
            ></md-progress-bar>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
const provisionApiCode = window.VUE_APP_PROVISION_API_CODE;

export default {
  data() {
    return {
      audits: [],
      tableHeaderColor: "green",
      isLoading: true
    };
  },
  async mounted() {
    try {
      const res = await this.$provisionApi.get(
        `/auditlog?code=${provisionApiCode}`
      );
      this.audits = res.data;
    } catch {
      this.$notify({
        message: "Error while retrieving audit logs",
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "bottom",
        type: "danger"
      });
    } finally {
      this.isLoading = false;
    }
  }
};
</script>
