<template>
  <div class="md-layout-item text-center">
    <h5 class="md-layout-item">The tenant was successfully provisioned!</h5>
    <ul>
      <li class="pad-bottom">
        <span class="md-subheading">Setup Check Profile</span>
        <div class="md-caption">
          Check Profiles enable a set of Checks to run during a detection call.
          call. Check routing is required when multiple check profiles are
          enabled.
        </div>
        <div>
          <md-button
            v-for="(sr, i) in status.output.Stages"
            :key="i"
            class="md-info md-sm"
            style="margin-right: 10px"
            :href="sr.ControlCenterProfileUri"
            target="_blank"
          >
            {{ sr.Region }}-{{ sr.Stage }}
          </md-button>
        </div>
      </li>

      <li class="pad-bottom">
        <span class="md-subheading">Setup Standard Mappings</span>
        <br />
        <div class="md-caption">
          Standard Mappings allow customer specific values to be mapped to FRISS
          FRISS values that can be understood our products.
        </div>
        <div>
          <md-button
            v-for="(sr, i) in status.output.Stages"
            :key="i"
            class="md-info md-sm"
            style="margin-right: 10px"
            :href="sr.ControlCenterMappingsUri"
            target="_blank"
          >
            {{ sr.Region }}-{{ sr.Stage }}
          </md-button>
        </div>
      </li>

      <li class="pad-bottom">
        <span class="md-subheading">Import Users</span>
        <div class="md-caption">
          Easily import a set of users by uploading a .CSV (Comma Separated
          Separated Value) file.
        </div>
        <div>
          <md-button
            v-for="(sr, i) in status.output.Stages"
            :key="i"
            class="md-info md-sm"
            style="margin-right: 10px"
            :href="sr.ControlCenterImportUsersUri"
            target="_blank"
          >
            {{ sr.Region }}-{{ sr.Stage }}
          </md-button>
        </div>
      </li>

      <li class="pad-bottom" v-if="hasFcmOrTenantWeb">
        <span class="md-subheading">Create WSA Account</span>
        <div class="md-caption">
          A WSA Account is required for authenticating against SOAP-based
          (Legacy) Detection API.
        </div>
        <div>
          <md-button
            v-for="(sr, i) in status.output.Stages"
            :key="i"
            class="md-info md-sm"
            style="margin-right: 10px"
            :href="sr.WsaAccountUri"
            target="_blank"
          >
            {{ sr.Region }}-{{ sr.Stage }}
          </md-button>
        </div>
      </li>

      <li v-if="hasFcmOrTenantWeb">
        <span class="md-subheading">Setup or Import KB</span>
        <div class="md-caption">
          Detection requires a Knowledge Base to operate correctly. This
          dictates the indicators and data sources to execute.
        </div>
        <div>
          <md-button
            v-for="(sr, i) in status.output.Stages"
            :key="i"
            class="md-info md-sm"
            style="margin-right: 10px"
            :href="sr.KbManagementImportUri"
            target="_blank"
          >
            {{ sr.Region }}-{{ sr.Stage }}
          </md-button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { OCTOPUS_PROJECTS } from "@/components/ProvisionsPlugin/ProvisionConstants";

export default {
  name: "Success",
  props: {
    status: Object
  },
  computed: {
    tenant() {
      return this.$tenantStore.state.currentTenant;
    },
    OCTOPUS_PROJECTS() {
      return OCTOPUS_PROJECTS;
    },
    hasFcmOrTenantWeb() {
      return (
        !!this.tenant.octopusDeployments &&
        (!this.tenant.octopusDeployments[OCTOPUS_PROJECTS.FCM].skip ||
          !this.tenant.octopusDeployments[OCTOPUS_PROJECTS.TENANT_WEB].skip)
      );
    }
  }
};
</script>

<style scoped></style>
