<template>
  <md-step
    id="region"
    md-label="Stage?"
    :md-done.sync="stepper.region"
    :md-editable="
      !provisioning.started || (provisioning.started && provisioning.ended)
    "
  >
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100 md-size-100">
        <md-field>
          <label>Select the stages</label>
          <md-select name="stage" id="stage" multiple v-model="tenant.stages">
            <md-optgroup
              v-for="region in availableRegionsAndStages"
              :key="region.name"
              :label="region.name"
            >
              <md-option
                v-for="item in region.stages"
                :key="item.name"
                :value="getStageValue(region, item)"
                :disabled="getStagesBasedOnCloudConsent(item.name)"
              >
                {{ item.name }} ({{ region.regionCode.toUpperCase() }})
              </md-option>
            </md-optgroup>
          </md-select>
        </md-field>
        <span v-if="!tenant.cloudConsent">
          <small>
            <i>
              *Some stages have been disabled because the customer has not given
              given consent to send or save data in the cloud.
            </i>
          </small>
        </span>
      </div>
    </div>
    <div class="md-layout md-gutter md-alignment-center-center">
      <md-button
        class="md-primary"
        @click="nextStep"
        :disabled="this.tenant.stages.length === 0"
      >
        Next
      </md-button>
    </div>
  </md-step>
</template>

<script>
export default {
  name: "RegionStep",
  props: {
    provisioning: {
      type: Object,
      required: true
    },
    stepper: {
      type: Object,
      required: true
    },
    tenant: {
      type: Object,
      required: true
    },
    availableRegionsAndStages: {
      type: Array,
      required: true
    }
  },
  methods: {
    getStageValue(region, stage) {
      const suffix = stage.cloudBased ? "-az" : "-saas";
      return `${region.regionCode}-${stage.value}${suffix}`;
    },
    getStagesBasedOnCloudConsent(text) {
      return text.indexOf("Azure") > 0 && !this.tenant.cloudConsent;
    },
    nextStep() {
      this.$emit("next-step", "region", "options");
    }
  }
};
</script>

<style scoped></style>
