<template>
  <md-step
    id="selectrelease"
    md-label="What Release?"
    :md-done.sync="stepper.selectrelease"
    :md-editable="
      !provisioning.started ||
      (provisioning.started && provisioning.ended) ||
      willDeployAnything
    "
  >
    <div class="md-layout-item md-small-size-100 md-size-100 pad-bottom">
      <h5>Select the Octopus Release's To Deploy:</h5>
      <div>
        <small>
          In order for Provisioning to work, a valid release version must be
          supplied for each project and each stage. Please copy and paste a
          valid release version to the respective textbox below.
          <br />
          <strong>
            Keep in mind that Development and Solvinity Octopus MAY have
            different versions available.
          </strong>
        </small>
      </div>
      <div v-if="stepper.options">
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <form @submit.prevent="onSubmit">
            <md-tabs style="padding-top: 25px" ref="releaseTabs">
              <md-tab
                v-for="stage in tenant.stages"
                :key="stage"
                :md-label="stage"
              >
                <div class="md-layout-item">
                  <ValidationProvider
                    immediate
                    v-slot="{ errors }"
                    rules="required"
                    v-if="!tenant.octopusDeployments[OCTOPUS_PROJECTS.FCM].skip"
                  >
                    <md-field :class="{ 'md-invalid': errors.length > 0 }">
                      <label>
                        {{ OCTOPUS_PROJECTS.FCM }}
                      </label>
                      <md-input
                        v-model="
                          tenant.octopusDeployments[OCTOPUS_PROJECTS.FCM]
                            .selectedVersion[stage]
                        "
                      ></md-input>
                      <span class="md-helper-text">
                        <i>Example: 2021.12.0-build2116000</i>
                      </span>
                      <span class="md-error">
                        {{ errors[0] }}
                      </span>
                    </md-field>
                  </ValidationProvider>
                  <ValidationProvider
                    immediate
                    v-slot="{ errors }"
                    rules="required"
                    v-if="
                      !tenant.octopusDeployments[OCTOPUS_PROJECTS.AUDIT].skip
                    "
                  >
                    <md-field :class="{ 'md-invalid': errors.length > 0 }">
                      <label>
                        {{ OCTOPUS_PROJECTS.AUDIT }}
                      </label>
                      <md-input
                        v-model="
                          tenant.octopusDeployments[OCTOPUS_PROJECTS.AUDIT]
                            .selectedVersion[stage]
                        "
                      ></md-input>
                      <span class="md-helper-text">
                        <i>Example: 2021.12.0-build2116000</i>
                      </span>
                      <span class="md-error">
                        {{ errors[0] }}
                      </span>
                    </md-field>
                  </ValidationProvider>
                  <ValidationProvider
                    immediate
                    v-slot="{ errors }"
                    rules="required"
                    v-if="
                      !tenant.octopusDeployments[OCTOPUS_PROJECTS.TENANT_WEB]
                        .skip
                    "
                  >
                    <md-field :class="{ 'md-invalid': errors.length > 0 }">
                      <label>
                        {{ OCTOPUS_PROJECTS.TENANT_WEB }}
                      </label>
                      <md-input
                        v-model="
                          tenant.octopusDeployments[OCTOPUS_PROJECTS.TENANT_WEB]
                            .selectedVersion[stage]
                        "
                      ></md-input>
                      <span class="md-helper-text">
                        <i>Example: 2021.12.0-build2116000</i>
                      </span>
                      <span class="md-error">
                        {{ errors[0] }}
                      </span>
                    </md-field>
                  </ValidationProvider>
                  <ValidationProvider
                    immediate
                    v-slot="{ errors }"
                    rules="required"
                    v-if="
                      !tenant.octopusDeployments[OCTOPUS_PROJECTS.SIDE_BY_SIDE]
                        .skip
                    "
                  >
                    <md-field :class="{ 'md-invalid': errors.length > 0 }">
                      <label>
                        {{ OCTOPUS_PROJECTS.SIDE_BY_SIDE }}
                      </label>
                      <md-input
                        v-model="
                          tenant.octopusDeployments[
                            OCTOPUS_PROJECTS.SIDE_BY_SIDE
                          ].selectedVersion[stage]
                        "
                      ></md-input>
                      <span class="md-helper-text">
                        <i>Example: 2021.12.0-build2116000</i>
                      </span>
                      <span class="md-error">
                        {{ errors[0] }}
                      </span>
                    </md-field>
                  </ValidationProvider>
                </div>
              </md-tab>
            </md-tabs>
            <div class="md-layout md-gutter md-alignment-center-center">
              <md-button
                class="md-primary"
                @click="onSubmit"
                :disabled="invalid"
              >
                Next
              </md-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </md-step>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import * as ProvisionConstants from "@/components/ProvisionsPlugin/ProvisionConstants";

extend("required", {
  ...required,
  message: "This field is required"
});

export default {
  name: "release-step",
  components: { ValidationProvider, ValidationObserver },
  props: {
    provisioning: {
      type: Object,
      required: true
    },
    stepper: {
      type: Object,
      required: true
    },
    tenant: {
      type: Object,
      required: true
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }

        this.$emit("next-step", "selectrelease", "keepasstokeyvault");
      });
    },
    fixDynamicTabContentHeight() {
      this.$refs.releaseTabs.$children[0].$el.removeAttribute("style");
    }
  },
  computed: {
    OCTOPUS_PROJECTS() {
      return ProvisionConstants.OCTOPUS_PROJECTS;
    },
    willDeployAnything() {
      return (
        this.tenant.octopusDeployments[this.OCTOPUS_PROJECTS.FCM].skip &&
        this.tenant.octopusDeployments[this.OCTOPUS_PROJECTS.TENANT_WEB].skip &&
        this.tenant.octopusDeployments[this.OCTOPUS_PROJECTS.SIDE_BY_SIDE]
          .skip &&
        this.tenant.octopusDeployments[this.OCTOPUS_PROJECTS.AUDIT].skip
      );
    }
  }
};
</script>

<style scoped></style>
