<template>
  <div class="md-layout-item text-center">
    <h5 class="md-layout-item">The provision will start soon</h5>
    <br />
    <div>
      <md-progress-bar
        class="bar"
        md-mode="buffer"
        :md-value="0"
        :md-buffer="0"
      ></md-progress-bar>
    </div>
    <br />
    <h6 class="md-layout-item">Please wait...</h6>
  </div>
</template>

<script>
export default {
  name: "Pending"
};
</script>

<style scoped>
.bar {
  width: 50%;
  min-width: 300px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
</style>
