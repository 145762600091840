<template>
  <md-dialog
    :md-active.sync="showDialog"
    :md-click-outside-to-close="false"
    :md-close-on-esc="false"
  >
    <md-dialog-title>{{ dialogTitle }}</md-dialog-title>
    <md-dialog-content>
      <div>
        <deprovision-status
          v-if="showStatus"
          :deprovision-status="deprovisionStatus"
        />
      </div>
      <div v-if="showConfirmation">
        <div>
          Are you sure you want to deprovision tenant
          <strong>{{ tenant.tenantCode }}</strong>
          ?
        </div>
        <br />
        <div>
          <md-field>
            <label>Confirm by typing the exact tenant code below.</label>
            <md-input v-model="confirmationText" type="text"></md-input>
          </md-field>
        </div>
        <div>
          Warning! This is
          <strong>irreversible</strong>
          ! During the deprovision process, the following will steps occur:
          <ul>
            <li>Trigger 3 Octopus Run Books</li>
            <li>
              Deletes Tenant in Identity
              <em>- Clients, Users(*), and Api Keys will be removed</em>
            </li>
            <li>Tenant Specific Blob Containers from Data Lake</li>
            <li>Tenant Specific Secrets removed from Key Vault</li>
          </ul>
          <em>
            * If any user is assigned to more than one tenant, it will only be
            unassigned to that tenant.
          </em>
        </div>
      </div>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button @click="onClose">Close</md-button>
      <md-button
        v-show="showConfirmation"
        :disabled="confirmationText !== tenant.tenantCode"
        class="md-button md-danger"
        @click="onDeprovision"
      >
        Deprovision
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import DeprovisionStatus from "@/components/ProvisionStatus/Status/DeprovisionStatus";

const provisionApiCode = window.VUE_APP_PROVISION_API_CODE;

export default {
  name: "DeprovisionDialog",
  components: { DeprovisionStatus },
  data() {
    return {
      confirmationText: "",
      deprovisionRunning: false,
      deprovisionResponseMetadata: {
        id: "",
        statusQueryGetUri: "",
        sendEventPostUri: "",
        terminatePostUri: "",
        purgeHistoryDeleteUri: "",
        restartPostUri: ""
      },
      deprovisionStatus: {
        name: "",
        instanceId: "",
        runtimeStatus: "",
        input: null,
        customStatus: "Please wait...",
        output: "",
        createdTime: "",
        lastUpdatedTime: ""
      },
      timerId: null
    };
  },
  computed: {
    dialogTitle() {
      return this.deprovisionRunning
        ? "Deprovision in progress"
        : "Deprovision Tenant";
    },
    tenant() {
      return this.$tenantStore.state.currentTenant;
    },
    showDialog() {
      return this.$tenantStore.state.showDeprovisionDialog;
    },
    showStatus() {
      return (
        this.deprovisionRunning ||
        this.deprovisionStatus.runtimeStatus.toLowerCase() === "completed"
      );
    },
    showConfirmation() {
      return !this.deprovisionRunning && !this.deprovisionStatus.runtimeStatus;
    }
  },
  methods: {
    onClose() {
      this.$tenantStore.closeDeprovisionDialog();
    },
    async onDeprovision() {
      if (this.tenant.tenantCode) {
        this.deprovisionRunning = true;

        try {
          const header = {
            headers: { "requested-by": this.$adal.user.profile.email }
          };
          const payload = {
            TenantCode: this.tenant.tenantCode,
            Name: this.tenant.name
          };
          const provisionAppResponse = await this.$provisionApi.post(
            `/deprovision?code=${provisionApiCode}`,
            payload,
            header
          );
          if (provisionAppResponse.status === 202) {
            this.deprovisionResponseMetadata = provisionAppResponse.data;
            this.pollDeprovisionStatus();
          }
        } catch (error) {
          let message = "Failed to Deprovision Tenant";
          this.deprovisionStatus.customStatus = message;
          this.deprovisionRunning = false;
          this.$notify({
            message: "Failed to deprovision Tenant in Provision App",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "danger"
          });
        }
      }
    },
    pollDeprovisionStatus() {
      const intervalTimeInMs = 5000;
      this.timerId = setInterval(async () => {
        const statusResponse = await this.$http.get(
          this.deprovisionResponseMetadata.statusQueryGetUri
        );

        this.deprovisionStatus = statusResponse.data;

        const deploymentHasFailedCompletedOrTerminated =
          this.deprovisionStatus.runtimeStatus.toLowerCase() === "failed" ||
          this.deprovisionStatus.runtimeStatus.toLowerCase() === "completed" ||
          this.deprovisionStatus.runtimeStatus.toLowerCase() === "terminated";

        if (deploymentHasFailedCompletedOrTerminated) {
          clearInterval(this.timerId);
        }
      }, intervalTimeInMs);
    }
  },
  beforeDestroy() {
    clearInterval(this.timerId);
  }
};
</script>

<style scoped></style>
