import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";
import Tenants from "@/pages/Tenants.vue";
import Provision from "@/pages/Provisioning.vue";
import Unauthorized from "@/pages/Unauthorized.vue";
import AuditLogs from "@/pages/AuditLogs.vue";

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/tenants",
    children: [
      {
        path: "provisioning",
        name: "Provisoning",
        component: Provision,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "tenants",
        name: "Tenants",
        component: Tenants,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "audit-logs",
        name: "Audit Logs",
        component: AuditLogs,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "unauthorized",
        name: "Unauthorized",
        component: Unauthorized,
        meta: {
          requireAuth: false
        }
      }
    ]
  }
];

export default routes;
