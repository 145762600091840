<template>
  <md-step
    id="review"
    md-label="Review"
    :md-done.sync="stepper.review"
    :md-editable="
      !provisioning.started || (provisioning.started && provisioning.ended)
    "
  >
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <h5 class="md-layout-item">
          <i>You're almost there!</i>
          <br />
          Before the tenant can start to be provisioned, please have a look
          below that all information is correct.
        </h5>
      </div>
      <div class="md-layout-item md-size-50">
        <div class="md-layout-item md-size-100">
          <h6><strong>Tenant Information</strong></h6>
          <md-field>
            <label>Tenant Code</label>
            <md-input
              v-model="tenant.tenantCode"
              type="text"
              disabled
            ></md-input>
          </md-field>
          <md-field>
            <label>Name</label>
            <md-input v-model="tenant.name" disabled type="text"></md-input>
          </md-field>
          <md-field>
            <label>Product</label>
            <md-input
              name="product"
              id="product"
              type="text"
              v-model="tenant.product"
              disabled
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout">
          <div
            class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
          >
            <md-field>
              <label>Country</label>
              <md-input
                name="country"
                id="country"
                type="text"
                v-model="tenant.country"
                disabled
              ></md-input>
            </md-field>
          </div>
          <div
            class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
          >
            <md-field>
              <label>Language</label>
              <md-input
                name="language"
                id="language"
                type="text"
                v-model="tenant.language"
                disabled
              ></md-input>
            </md-field>
          </div>
        </div>
        <label class="md-layout-item md-size-100">
          <md-icon v-if="tenant.cloudConsent">check</md-icon>
          <md-icon v-if="!tenant.cloudConsent">clear</md-icon>
          Customer has
          <span v-if="!tenant.cloudConsent">
            <strong>not</strong>
          </span>
          agreed to have data processed and stored in the cloud.
        </label>
      </div>
      <div class="md-layout-item md-size-50">
        <div class="md-layout-item md-size-100">
          <div class="md-layout">
            <div class="md-size-50">
              <h6><strong>Administrators</strong></h6>
            </div>
          </div>
        </div>
        <div
          class="md-layout"
          v-for="(admin, index) in tenant.tenantAdmins"
          :key="index"
        >
          <div
            class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
          >
            <md-field>
              <label>Email Address</label>
              <md-input
                v-model="admin.emailAddress"
                disabled
                type="text"
              ></md-input>
            </md-field>
          </div>
          <div
            class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
          >
            <md-field>
              <label>First Name</label>
              <md-input
                v-model="admin.firstName"
                disabled
                type="text"
              ></md-input>
            </md-field>
          </div>
          <div
            class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
          >
            <md-field>
              <label>Last Name</label>
              <md-input
                v-model="admin.lastName"
                disabled
                type="text"
              ></md-input>
            </md-field>
          </div>
        </div>
      </div>
      <div class="md-layout-item md-size-50">
        <md-empty-state
          v-if="noFeatureOrProductFlags"
          md-icon="sentiment_very_dissatisfied"
          md-description="No additional product or feature flags are toggled on. Go back to step 'Anything Else?'
 if you want additional products or features."
        ></md-empty-state>
        <div
          class="md-layout-item md-small-size-100 md-size-100"
          v-if="tenant.productFlags.length > 0"
        >
          <h6 class="subheader"><strong>Product Flags</strong></h6>
          <div v-for="item in tenant.productFlags" :key="item.code">
            <label
              class="md-just-icon md-simple md-primary"
              style="height: 15px"
            >
              <md-icon>check</md-icon>
              <md-tooltip md-direction="top">
                {{ item.description }}
              </md-tooltip>
              {{ item.name }}
            </label>
          </div>
        </div>
        <div
          class="md-layout-item md-small-size-100 md-size-100"
          v-if="tenant.featureFlags.length > 0"
        >
          <h6 class="subheader"><strong>Features Flags</strong></h6>
          <div v-for="item in tenant.featureFlags" :key="item.code">
            <label
              class="md-just-icon md-simple md-primary"
              style="height: 15px"
            >
              <md-icon>check</md-icon>
              <md-tooltip md-direction="top">
                {{ item.description }}
              </md-tooltip>
              {{ item.name }}
            </label>
          </div>
        </div>
      </div>
      <div class="md-layout-item md-size-100">
        <div class="md-layout-item md-size-100">
          <h6 class="subheader"><strong>Stages</strong></h6>
          <div class="md-layout md-gutter">
            <div
              class="md-layout-item"
              v-for="region in availableRegionsAndStages"
              :key="region.id"
            >
              <md-list>
                <md-subheader>{{ region.name }}</md-subheader>

                <md-list-item v-for="stage in region.stages" :key="stage.name">
                  <md-checkbox
                    v-model="tenant.stages"
                    :value="getStageValue(region, stage)"
                    disabled
                  />
                  <span class="md-list-item-text">
                    {{ stage.name }}
                  </span>
                </md-list-item>
              </md-list>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="md-layout md-gutter md-alignment-center-center">
      <md-checkbox v-model="reviewVerified">
        Yes, this tenant information is correct. Proceed with tenant
        provisioning.
      </md-checkbox>
    </div>
    <div class="md-layout md-gutter md-alignment-center-center">
      <md-button
        class="md-primary"
        @click="provisionTenant"
        :disabled="!reviewVerified"
      >
        Next
      </md-button>
    </div>
  </md-step>
</template>

<script>
export default {
  name: "ReviewStep",
  data() {
    return {
      reviewVerified: false
    };
  },
  props: {
    provisioning: {
      type: Object,
      required: true
    },
    stepper: {
      type: Object,
      required: true
    },
    tenant: {
      type: Object,
      required: true
    },
    availableRegionsAndStages: {
      type: Array,
      required: true
    }
  },
  computed: {
    noFeatureOrProductFlags() {
      return (
        this.tenant.featureFlags.length === 0 &&
        this.tenant.productFlags.length === 0
      );
    }
  },
  methods: {
    nextStep() {
      this.$emit("next-step", "keepasstokeyvault", "review");
    },
    provisionTenant() {
      this.$emit("provision-tenant");
    },
    getStageValue(region, stage) {
      const suffix = stage.cloudBased ? "-az" : "-saas";
      return `${region.regionCode}-${stage.value}${suffix}`;
    }
  }
};
</script>

<style>
.md-list-item-text {
  position: unset !important;
}
</style>
