<template>
  <md-table-row>
    <md-table-cell>
      {{ tenant.name }}
    </md-table-cell>
    <md-table-cell width="10%">
      <md-button
        v-if="userCanWrite"
        :disabled="isProvisionInProgress || isDeprovisionInProgress"
        class="md-just-icon md-simple md-primary"
        @click="edit"
      >
        <md-icon>edit</md-icon>
        <md-tooltip md-direction="top">Edit</md-tooltip>
      </md-button>

      <div v-if="isProvisionInProgress">
        <md-tooltip md-direction="top">
          <div>
            <div
              v-for="(provision, provIndex) in provisionsInProgress"
              :key="provision.provisionId + provIndex"
            >
              Provision is
              <strong>{{ provision.state }}</strong>
              for
              <div
                v-for="(env, envIndex) in provision.environments"
                :key="env + envIndex"
              >
                <md-chip class="stages-chip">
                  {{ env.stage }}-{{ env.region }}
                </md-chip>
              </div>
            </div>
          </div>
        </md-tooltip>
        <md-button class="md-just-icon md-simple md-info in-progress" disabled>
          <md-icon>cloud_upload</md-icon>
        </md-button>
        <div class="in-progress-button">
          <md-progress-bar md-mode="indeterminate"></md-progress-bar>
        </div>
      </div>
      <md-button
        v-if="
          userCanWriteProvision && !isProvisionInProgress && tenant.cloudConsent
        "
        :disabled="isDeprovisionInProgress || !isProvisionAppApiAvailable"
        class="md-just-icon md-simple md-info"
        @click="deploy"
      >
        <md-icon>cloud_upload</md-icon>
        <md-tooltip md-direction="top">Provision</md-tooltip>
      </md-button>

      <md-button
        v-if="tenant.cloudConsent && userCanWriteProvision"
        :disabled="
          isProvisionInProgress ||
          isDeprovisionInProgress ||
          !isProvisionAppApiAvailable
        "
        class="md-just-icon md-simple md-info"
        @click="onAllowlisting"
      >
        <md-icon>rule</md-icon>
        <md-tooltip md-direction="top">IP Allowlisting</md-tooltip>
      </md-button>

      <md-menu md-direction="bottom-end" md-align-trigger>
        <md-button md-menu-trigger class="md-just-icon md-simple md-info"
          v-show="tenant.cloudConsent && userCanWriteProvision && !isDevelopmentStage"
          :disabled="
            isProvisionInProgress ||
            isDeprovisionInProgress ||
            !isProvisionAppApiAvailable
          ">
          <md-icon>token</md-icon>
          <md-tooltip md-direction="top">Integrations</md-tooltip>
        </md-button>
        <md-menu-content>
          <md-menu-item @click="onFeiIntegration">Enterprise Investigations</md-menu-item>
        </md-menu-content>
      </md-menu>

      <div v-if="isDeprovisionInProgress">
        <md-tooltip md-direction="top">
          <div>
            <div
              v-for="(deprovision, depIndex) in deprovisionsInProgress"
              :key="deprovision.provisionId + depIndex"
            >
              Deprovision is
              <strong>{{ deprovision.state }}</strong>
              for
              <div
                v-for="(env, envIndex) in deprovision.environments"
                :key="env + envIndex"
              >
                <md-chip class="stages-chip">
                  {{ env.stage }}-{{ env.region }}
                </md-chip>
              </div>
            </div>
          </div>
        </md-tooltip>
        <md-button
          class="md-just-icon md-simple md-danger in-progress"
          disabled
        >
          <md-icon>cloud_download</md-icon>
        </md-button>
        <div class="in-progress-button">
          <md-progress-bar md-mode="indeterminate"></md-progress-bar>
        </div>
      </div>
      <md-button
        v-if="
          userCanDeleteProvision &&
          tenantHasProvision &&
          !isDeprovisionInProgress
        "
        :disabled="isProvisionInProgress || !isProvisionAppApiAvailable"
        class="md-just-icon md-simple md-danger"
        @click="onDeprovision"
      >
        <md-icon>cloud_download</md-icon>
        <md-tooltip md-direction="top">Deprovision</md-tooltip>
      </md-button>
      <md-button
        v-if="userCanDeleteProvision && !tenantHasProvision"
        :disabled="isProvisionInProgress || !isProvisionAppApiAvailable"
        class="md-just-icon md-simple md-danger"
        @click="onDelete"
      >
        <md-icon>delete</md-icon>
        <md-tooltip md-direction="top">Delete</md-tooltip>
      </md-button>
    </md-table-cell>
  </md-table-row>
</template>

<script>

const namespace = window.VUE_APP_NAMESPACE;

export default {
  name: "TenantTableRow",
  props: {
    tenant: {
      type: Object,
      required: true
    }
  },
  computed: {
    isDevelopmentStage() {
      return namespace.toLowerCase() === "dev";
    },
    tenantHasProvision() {
      return (
        Array.isArray(this.tenant.provisions) &&
        this.tenant.provisions.length > 0
      );
    },
    isProvisionInProgress() {
      return (
        this.tenantHasProvision &&
        this.tenant.provisions.filter(
          p =>
            p.type.toLowerCase() === "provision" &&
            p.state.toLowerCase() !== "completed" &&
            p.state.toLowerCase() !== "terminated" &&
            p.state.toLowerCase() !== "failed"
        ).length > 0
      );
    },
    isDeprovisionInProgress() {
      return (
        this.tenantHasProvision &&
        this.tenant.provisions.filter(
          p =>
            p.type.toLowerCase() === "deprovision" &&
            p.state.toLowerCase() !== "completed" &&
            p.state.toLowerCase() !== "terminated" &&
            p.state.toLowerCase() !== "failed"
        ).length > 0
      );
    },
    provisionsInProgress() {
      return this.isProvisionInProgress
        ? this.tenant.provisions.filter(
            p =>
              p.type.toLowerCase() === "provision" &&
              p.state.toLowerCase() !== "completed" &&
              p.state.toLowerCase() !== "terminated" &&
              p.state.toLowerCase() !== "failed"
          )
        : [];
    },
    deprovisionsInProgress() {
      return this.isDeprovisionInProgress
        ? this.tenant.provisions.filter(
            p =>
              p.type.toLowerCase() === "deprovision" &&
              p.state.toLowerCase() !== "completed" &&
              p.state.toLowerCase() !== "terminated" &&
              p.state.toLowerCase() !== "failed"
          )
        : [];
    },
    isProvisionAppApiAvailable() {
      return this.$tenantStore.state.provisionAppApiAvailable;
    }
  },
  asyncComputed: {
    async userCanWrite() {
      return await this.hasCurrentUserWritePermission();
    },
    async userCanWriteProvision() {
      return await this.hasCurrentUserProvisionWritePermission();
    },
    async userCanDeleteProvision() {
      return await this.hasCurrentUserProvisionDeletePermission();
    }
  },
  methods: {
    edit() {
      this.$tenantStore.setTenantToEdit(this.tenant.id);
    },
    deploy() {
      this.$tenantStore.setCurrentTenant(this.tenant);
      this.$tenantStore.openProvisionDialog();
    },
    onAllowlisting() {
      this.$tenantStore.setCurrentTenant(this.tenant);
      this.$tenantStore.openAllowlistingDialog();
    },
    onDelete() {
      this.$tenantStore.setCurrentTenant(this.tenant);
      this.$tenantStore.openDeleteDialog();
    },
    onDeprovision() {
      this.$tenantStore.setCurrentTenant(this.tenant);
      this.$tenantStore.openDeprovisionDialog();
    },
    onFeiIntegration() {
      this.$tenantStore.setCurrentTenant(this.tenant);
      this.$tenantStore.openFeiDialog();
    }
  }
};
</script>

<style scoped>
.md-progress-bar {
  height: 2px;
}
.in-progress-button {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.md-button.md-just-icon.in-progress {
  height: 31px;
  margin-bottom: 0;
  margin-top: 15px;
}
.md-menu>.md-button {
  margin: 10px 1px;
}
.stages-chip {
  line-height: 18px;
  height: 18px;
  padding: 0 8px;
}
</style>
