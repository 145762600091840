import Vue from "vue";

const provisionApiCode = window.VUE_APP_PROVISION_API_CODE;

const store = Vue.observable({
  tenants: [],
  currentTenant: {},
  tenantToEdit: {},
  countries: [],
  languages: [],
  currencies: [],
  showCreateDialog: false,
  showProvisionDialog: false,
  showProvisionStatusDialog: false,
  showAllowlistingDialog: false,
  showDeleteDialog: false,
  showDeprovisionDialog: false,
  showFeiDialog: false,
  provisionAppApiAvailable: false,
});

const tenantStore = {
  get state() {
    return store;
  },

  async loadTenants() {
    const res = await Vue.prototype.$featureFlagApi.get("/tenants");
    const responseData = res.data;

    responseData.map(c => {
      c.ipAllowListing = { ipaddresses: [], region: "" };
      c.integrations = { region: "", fei: {
        authurl: "",
        clientid: "",
        clientsecret: "",
        eventrouter: {
          deliveryurl: "",
          deliveryauthurl: "",
          clientid: "",
          clientsecret: "",
          scope: ""
        }
      }};
      c.provisions = [];
    });

    store.tenants = responseData;
  },
  setCurrentTenant(currentTenant) {
    Object.assign(store.currentTenant, structuredClone(currentTenant));
  },
  async setTenantToEdit(tenantId) {
    // make call to get single tenant details because we want to retrieve Azure App Configuration (tenant settings) for only this specific tenant
    const res = await Vue.prototype.$featureFlagApi.get(
      `/tenants/${tenantId}`
    );
    Object.assign(store.tenantToEdit, structuredClone(res.data));
    this.openCreateEditDialog();
  },
  async addTenant(newTenant) {
    const response = await Vue.prototype.$featureFlagApi.post(
      `/tenants`,
      newTenant
    );
    if (response.status === 201) {
      const createdTenant = response.data;
      createdTenant.provisions = [];
      createdTenant.ipAllowListing = { ipaddresses: [], region: "" };
      createdTenant.integrations = { region: "", fei: {
        authurl: "",
        clientid: "",
        clientsecret: "",
        eventrouter: {
          deliveryurl: "",
          deliveryauthurl: "",
          clientid: "",
          clientsecret: "",
          scope: ""
        }
      }};
      store.tenants = [...store.tenants, createdTenant];
    }
  },
  async updateTenant(updatedTenant) {
    const response = await Vue.prototype.$featureFlagApi.put(
      `/tenants/${updatedTenant.id}`,
      updatedTenant
    );
    if (response.status === 200) {
      const tenantToUpdate = store.tenants.find(
        t => t.tenantCode === updatedTenant.tenantCode
      );
      Object.assign(tenantToUpdate, updatedTenant);
    }
  },
  async deleteCurrentTenant() {
    const response = await Vue.prototype.$featureFlagApi.delete(
      `/tenants/${store.currentTenant.id}`
    );
    if (response.status == 204) {
      store.tenants = store.tenants.filter(
        t => t.id !== store.currentTenant.id
      );
    }
  },
  async loadProvisionsForTenant() {
    try {
      const provisionsResponse = await Vue.prototype.$provisionApi.post(
        `/provision/status/tenants?code=${provisionApiCode}`,
        { TenantCodes: store.tenants.map(x => x.tenantCode) }
      );

      if(!provisionsResponse.data && !Array.isArray(provisionsResponse.data)){
        return;
      }

      const provisionsRunning = provisionsResponse.data;
      const updatedTenants = structuredClone(store.tenants);
      updatedTenants.map(t => (t.provisions = []));
      provisionsRunning.map(p => {
        const tenantIndex = updatedTenants.findIndex(
          i => i.tenantCode === p.tenantCode
        );
        updatedTenants[tenantIndex] = {
          ...updatedTenants[tenantIndex],
          provisions: [...updatedTenants[tenantIndex].provisions, p]
        };
      });

      store.tenants = updatedTenants;
      store.provisionAppApiAvailable = true;
    } catch (error) {
      store.provisionAppApiAvailable = false;
      throw error;
    }
  },
  async loadLocalization() {
    const localizationResponse = await Vue.prototype.$featureFlagApi.get(
      "/localization"
    );

    store.countries = localizationResponse.data.supportedCountries;
    store.languages = localizationResponse.data.supportedLanguages;
    store.currencies = localizationResponse.data.supportedCurrencies;
  },
  openCreateEditDialog() {
    store.showCreateDialog = true;
  },
  closeCreateEditDialog() {
    store.tenantToEdit = {};
    store.showCreateDialog = false;
  },
  openDeleteDialog() {
    store.showDeleteDialog = true;
  },
  closeDeleteDialog() {
    store.currentTenant = {};
    store.showDeleteDialog = false;
  },
  openDeprovisionDialog() {
    store.showDeprovisionDialog = true;
  },
  closeDeprovisionDialog() {
    store.currentTenant = {};
    store.showDeprovisionDialog = false;
  },
  openAllowlistingDialog() {
    store.showAllowlistingDialog = true;
  },
  closeAllowlistingDialog() {
    store.currentTenant = {};
    store.showAllowlistingDialog = false;
  },
  openProvisionDialog() {
    store.showProvisionDialog = true;
  },
  closeProvisionDialog() {
    store.currentTenant = {};
    store.showProvisionDialog = false;
  },
  openFeiDialog() {
    store.showFeiDialog = true;
  },
  closeFeiDialog() {
    store.currentTenant = {};
    store.showFeiDialog = false;
  }
};

export default {
  install(Vue, options) {
    Vue.prototype.$tenantStore = tenantStore;
  }
};
