<template>
  <footer class="footer">
    <div class="container">
      <nav></nav>
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}
        <a href="https://friss.com" target="_blank">FRISS</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
