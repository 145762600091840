<template>
  <div class="md-layout-item text-center">
    <br />
    <h6 class="md-layout-item">
      <h5 class="md-layout-item">Deployment has failed!</h5>
      <h6 class="md-layout-item">
        {{ status.output }}
      </h6>
    </h6>
  </div>
</template>

<script>
export default {
  name: "Failed",
  props: {
    status: Object
  }
};
</script>

<style scoped></style>
