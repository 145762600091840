var render = function render(){var _vm=this,_c=_vm._self._c;return _c('md-step',{attrs:{"id":"keepasstokeyvault","md-label":"Migrate Secrets","md-done":_vm.stepper.keepasstokeyvault,"md-editable":!_vm.provisioning.started || (_vm.provisioning.started && _vm.provisioning.ended)},on:{"update:mdDone":function($event){return _vm.$set(_vm.stepper, "keepasstokeyvault", $event)},"update:md-done":function($event){return _vm.$set(_vm.stepper, "keepasstokeyvault", $event)}}},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-100 pad-bottom"},[_c('h5',[_vm._v("Migrate Secrets from Kee Pass to Key Vault?")]),_c('md-switch',{model:{value:(_vm.tenant.writeToKeyVault.write),callback:function ($$v) {_vm.$set(_vm.tenant.writeToKeyVault, "write", $$v)},expression:"tenant.writeToKeyVault.write"}},[_vm._v(" Yes, this tenant currently has secrets stored in KeePass but not KeyVault. ")]),_c('div',[_c('small',[_vm._v(" Switching the flag on will require you to fill in secrets for existing products. "),_c('br'),_vm._v(" (Since KeePass does not have a standard structure/convention, it's next to impossible to automate. Octopus treats secrets in a way that it's impossible to retrive them again.) "),_c('br'),_c('strong',[_vm._v(" This is a one time action, secrets already stored in KeyVault will not be overwritten. "),_c('br'),_vm._v(" Any secrets left empty will be ignored, and during the provisioning process, new secrets will be generated and saved in KeyVault. ")])])]),(_vm.tenant.writeToKeyVault.write)?_c('div',[_c('md-tabs',{staticStyle:{"padding-top":"25px"}},_vm._l((_vm.tenant.stages),function(stage){return _c('md-tab',{key:stage,attrs:{"md-label":stage}},[_c('div',{staticClass:"md-layout-item"},[_c('md-field',[_c('label',[_vm._v("Legacy Service Password")]),_c('md-input',{attrs:{"type":"password"},model:{value:(
                  _vm.tenant.writeToKeyVault.stages[stage].legacyServicePassword
                ),callback:function ($$v) {_vm.$set(_vm.tenant.writeToKeyVault.stages[stage], "legacyServicePassword", $$v)},expression:"\n                  tenant.writeToKeyVault.stages[stage].legacyServicePassword\n                "}}),_c('span',{staticClass:"md-helper-text"},[_c('i',[_vm._v("Octopus Variable:")]),_c('strong',[_vm._v("Service Password")]),_vm._v(" - The secret used for authenticating against Windows Service account. ")])],1),_c('md-field',[_c('label',[_vm._v("Legacy Database Password")]),_c('md-input',{attrs:{"type":"password"},model:{value:(
                  _vm.tenant.writeToKeyVault.stages[stage].legacyDatabasePassword
                ),callback:function ($$v) {_vm.$set(_vm.tenant.writeToKeyVault.stages[stage], "legacyDatabasePassword", $$v)},expression:"\n                  tenant.writeToKeyVault.stages[stage].legacyDatabasePassword\n                "}}),_c('span',{staticClass:"md-helper-text"},[_c('i',[_vm._v("Octopus Variable:")]),_c('strong',[_vm._v("Database Password")]),_vm._v(" - The secret used for authenticating against SQL account. ")])],1),_c('md-field',[_c('label',[_vm._v("Legacy Identity Client Secret")]),_c('md-input',{attrs:{"type":"password"},model:{value:(
                  _vm.tenant.writeToKeyVault.stages[stage]
                    .legacyIdentityClientSecret
                ),callback:function ($$v) {_vm.$set(_vm.tenant.writeToKeyVault.stages[stage]
                    , "legacyIdentityClientSecret", $$v)},expression:"\n                  tenant.writeToKeyVault.stages[stage]\n                    .legacyIdentityClientSecret\n                "}}),_c('span',{staticClass:"md-helper-text"},[_c('i',[_vm._v("Octopus Variable:")]),_c('strong',[_vm._v("Back-end Identity(v3) Client Secret")]),_vm._v(" - The secret used for communication with legacy Identity and Case Management. ")])],1),_c('md-field',[_c('label',[_vm._v("FRISS Cloud Api Key")]),_c('md-input',{attrs:{"type":"password"},model:{value:(_vm.tenant.writeToKeyVault.stages[stage].cloudApiKey),callback:function ($$v) {_vm.$set(_vm.tenant.writeToKeyVault.stages[stage], "cloudApiKey", $$v)},expression:"tenant.writeToKeyVault.stages[stage].cloudApiKey"}}),_c('span',{staticClass:"md-helper-text"},[_c('i',[_vm._v("No Octopus Variable")]),_vm._v(" - The secret used for communication between Detection and Cloud. ")])],1),_c('md-field',[_c('label',[_vm._v("Case Management Client Secret")]),_c('md-input',{attrs:{"type":"password"},model:{value:(
                  _vm.tenant.writeToKeyVault.stages[stage]
                    .caseManagementClientSecret
                ),callback:function ($$v) {_vm.$set(_vm.tenant.writeToKeyVault.stages[stage]
                    , "caseManagementClientSecret", $$v)},expression:"\n                  tenant.writeToKeyVault.stages[stage]\n                    .caseManagementClientSecret\n                "}}),_c('span',{staticClass:"md-helper-text"},[_c('i',[_vm._v("Octopus Variable:")]),_c('strong',[_vm._v("OpenId Connect Client Secret")]),_vm._v(" - The secret used for communication with Cloud Identity and Case Management when "),_c('strong',[_vm._v("Single Sign On")]),_vm._v(" is enabled for the tenant. ")])],1),_c('md-field',[_c('label',[_vm._v("Portal Client Secret")]),_c('md-input',{attrs:{"type":"password"},model:{value:(
                  _vm.tenant.writeToKeyVault.stages[stage].portalClientSecret
                ),callback:function ($$v) {_vm.$set(_vm.tenant.writeToKeyVault.stages[stage], "portalClientSecret", $$v)},expression:"\n                  tenant.writeToKeyVault.stages[stage].portalClientSecret\n                "}}),_c('span',{staticClass:"md-helper-text"},[_c('i',[_vm._v("No Octopus Variable")]),_vm._v(" - The secret used for communication with Cloud Identity and Portal when "),_c('strong',[_vm._v("Single Sign On")]),_vm._v(" is enabled for the tenant. ")])],1),_c('md-field',[_c('label',[_vm._v("FRISS Investigations Api Key")]),_c('md-input',{attrs:{"type":"password"},model:{value:(
                  _vm.tenant.writeToKeyVault.stages[stage]
                    .factsCaseManagementApiKey
                ),callback:function ($$v) {_vm.$set(_vm.tenant.writeToKeyVault.stages[stage]
                    , "factsCaseManagementApiKey", $$v)},expression:"\n                  tenant.writeToKeyVault.stages[stage]\n                    .factsCaseManagementApiKey\n                "}}),_c('span',{staticClass:"md-helper-text"},[_c('i',[_vm._v("Octopus Variable:")]),_c('strong',[_vm._v("Facts API Key")]),_vm._v(" - The secret used to allow Case Management to communicate with FRISS Investigations. ")])],1)],1)])}),1)],1):_vm._e()],1),_c('div',{staticClass:"md-layout md-gutter md-alignment-center-center"},[_c('md-button',{staticClass:"md-primary",on:{"click":_vm.nextStep}},[_vm._v("Next")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }