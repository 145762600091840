<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="green">
                        <h4 class="title" style="display: inline-block; margin-top: 5px;">Tenants</h4>
                        <md-button class="md-primary md-raised" style="float: right"
                                   @click="showCreateEditDialog">
                            New Tenant
                        </md-button>
                    </md-card-header>
                    <md-card-content>
                        <md-table>
                            <md-progress-bar v-if="isLoadingTenantList"
                                             md-mode="indeterminate"></md-progress-bar>
                            <md-empty-state md-label="No Tenants were found."
                                            md-description="No Tenants were found in the database but you can create the first one."
                                            v-show="showEmptyState"></md-empty-state>

                            <tenant-table-row v-for="tenant in tenants"
                                              :key="tenant.tenantCode"
                                              :tenant="tenant" />
                        </md-table>
                    </md-card-content>
                </md-card>
            </div>
            <create-edit-tenant v-if="showCreateDialog" />
            <provision-dialog v-if="showProvisionDialog" />
            <delete-dialog v-if="showDeleteDialog" />
            <allowlisting-dialog v-if="showAllowlistingDialog" />
            <deprovision-dialog v-if="showDeprovisionDialog" />
            <fei-integrations-dialog v-if="showFeiDialog" />
        </div>
    </div>
</template>

<script>
    import TenantTableRow from "../components/Tenants/TenantTableRow";
    import CreateEditTenant from "../components/Tenants/CreateEditTenant";
    import ProvisionDialog from "@/components/Tenants/Provision/ProvisionDialog";
    import DeleteDialog from "../components/Tenants/DeleteDialog";
    import AllowlistingDialog from "@/components/Tenants/AllowlistingDialog";
    import DeprovisionDialog from "@/components/Tenants/DeprovisionDialog";
    import FeiIntegrationsDialog from "@/components/Tenants/Integrations/FEIDialog.vue";

    export default {
        name: "Tenants",
        components: {
            DeprovisionDialog,
            AllowlistingDialog,
            ProvisionDialog,
            TenantTableRow,
            CreateEditTenant,
            DeleteDialog,
            FeiIntegrationsDialog
        },
        data() {
            return {
                initialLoadFinished: false,
                isLoadingTenantList: true,
                pollProvisionsTimerId: null
            };
        },
        computed: {
            tenants() {
                return this.$tenantStore.state.tenants;
            },
            showEmptyState() {
                return (
                    !this.isLoadingTenantList &&
                    this.$tenantStore.state.tenants.length === 0
                );
            },
            showCreateDialog() {
                return this.$tenantStore.state.showCreateDialog;
            },
            showDeleteDialog() {
                return this.$tenantStore.state.showDeleteDialog;
            },
            showProvisionDialog() {
                return this.$tenantStore.state.showProvisionDialog;
            },
            showAllowlistingDialog() {
                return this.$tenantStore.state.showAllowlistingDialog;
            },
            showDeprovisionDialog() {
                return this.$tenantStore.state.showDeprovisionDialog;
            },
            showFeiDialog() {
                return this.$tenantStore.state.showFeiDialog;
            },
            currentTenant() {
                return this.$tenantStore.state.currentTenant;
            }
        },
        async created() {
            try {
                await this.$tenantStore.loadLocalization();
                await this.$tenantStore.loadTenants();
                this.isLoadingTenantList = false;
            } catch (error) {
                let message = "Failed to load Tenants";
                if (error.response)
                    message += `: ${error.response.data.errors.join(" ")}`;

                this.$notify({
                    message: message,
                    icon: "add_alert",
                    horizontalAlign: "right",
                    verticalAlign: "bottom",
                    type: "danger"
                });
                this.isLoadingTenantList = false;
            }
            await this.callProvisionApi();
            await this.pollProvisions();
            this.initialLoadFinished = true;
        },
        methods: {
            showCreateEditDialog() {
                this.$tenantStore.openCreateEditDialog();
            },
            async pollProvisions() {
                const intervalTimeInMs = 10000;
                this.pollProvisionsTimerId = setInterval(async () => {
                    await this.callProvisionApi();
                }, intervalTimeInMs);
            },
            async callProvisionApi() {
                try {
                    await this.$tenantStore.loadProvisionsForTenant();
                    if (
                        !this.$tenantStore.state.provisionAppApiAvailable &&
                        this.initialLoadFinished
                    ) {
                        this.$notify({
                            message: "Connection to provisioning api re-established!",
                            icon: "add_alert",
                            horizontalAlign: "right",
                            verticalAlign: "bottom",
                            type: "success"
                        });
                    }
                    this.$tenantStore.state.provisionAppApiAvailable = true;
                } catch (error) {
                    if (this.$tenantStore.state.provisionAppApiAvailable) {
                        this.$notify({
                            message: "Connection to provisioning api lost!",
                            icon: "add_alert",
                            horizontalAlign: "right",
                            verticalAlign: "bottom",
                            type: "danger"
                        });
                    }
                    this.$tenantStore.state.provisionAppApiAvailable = false;
                }
            }
        },
        beforeDestroy() {
            clearInterval(this.pollProvisionsTimerId);
        }
    };
</script>

<style scoped></style>
