<template>
  <md-step
    id="keepasstokeyvault"
    md-label="Migrate Secrets"
    :md-done.sync="stepper.keepasstokeyvault"
    :md-editable="
      !provisioning.started || (provisioning.started && provisioning.ended)
    "
  >
    <div class="md-layout-item md-small-size-100 md-size-100 pad-bottom">
      <h5>Migrate Secrets from Kee Pass to Key Vault?</h5>
      <md-switch v-model="tenant.writeToKeyVault.write">
        Yes, this tenant currently has secrets stored in KeePass but not
        KeyVault.
      </md-switch>
      <div>
        <small>
          Switching the flag on will require you to fill in secrets for existing
          products.
          <br />
          (Since KeePass does not have a standard structure/convention, it's
          next to impossible to automate. Octopus treats secrets in a way that
          it's impossible to retrive them again.)
          <br />
          <strong>
            This is a one time action, secrets already stored in KeyVault will
            not be overwritten.
            <br />
            Any secrets left empty will be ignored, and during the provisioning
            process, new secrets will be generated and saved in KeyVault.
          </strong>
        </small>
      </div>
      <div v-if="tenant.writeToKeyVault.write">
        <md-tabs style="padding-top: 25px">
          <md-tab v-for="stage in tenant.stages" :key="stage" :md-label="stage">
            <div class="md-layout-item">
              <md-field>
                <label>Legacy Service Password</label>
                <md-input
                  v-model="
                    tenant.writeToKeyVault.stages[stage].legacyServicePassword
                  "
                  type="password"
                ></md-input>
                <span class="md-helper-text">
                  <i>Octopus Variable:</i>
                  <strong>Service Password</strong>
                  - The secret used for authenticating against Windows Service
                  account.
                </span>
              </md-field>
              <md-field>
                <label>Legacy Database Password</label>
                <md-input
                  v-model="
                    tenant.writeToKeyVault.stages[stage].legacyDatabasePassword
                  "
                  type="password"
                ></md-input>
                <span class="md-helper-text">
                  <i>Octopus Variable:</i>
                  <strong>Database Password</strong>
                  - The secret used for authenticating against SQL account.
                </span>
              </md-field>
              <md-field>
                <label>Legacy Identity Client Secret</label>
                <md-input
                  v-model="
                    tenant.writeToKeyVault.stages[stage]
                      .legacyIdentityClientSecret
                  "
                  type="password"
                ></md-input>
                <span class="md-helper-text">
                  <i>Octopus Variable:</i>
                  <strong>Back-end Identity(v3) Client Secret</strong>
                  - The secret used for communication with legacy Identity and
                  Case Management.
                </span>
              </md-field>
              <md-field>
                <label>FRISS Cloud Api Key</label>
                <md-input
                  v-model="tenant.writeToKeyVault.stages[stage].cloudApiKey"
                  type="password"
                ></md-input>
                <span class="md-helper-text">
                  <i>No Octopus Variable</i>
                  - The secret used for communication between Detection and
                  Cloud.
                </span>
              </md-field>
              <md-field>
                <label>Case Management Client Secret</label>
                <md-input
                  v-model="
                    tenant.writeToKeyVault.stages[stage]
                      .caseManagementClientSecret
                  "
                  type="password"
                ></md-input>
                <span class="md-helper-text">
                  <i>Octopus Variable:</i>
                  <strong>OpenId Connect Client Secret</strong>
                  - The secret used for communication with Cloud Identity and
                  Case Management when
                  <strong>Single Sign On</strong>
                  is enabled for the tenant.
                </span>
              </md-field>
              <md-field>
                <label>Portal Client Secret</label>
                <md-input
                  v-model="
                    tenant.writeToKeyVault.stages[stage].portalClientSecret
                  "
                  type="password"
                ></md-input>
                <span class="md-helper-text">
                  <i>No Octopus Variable</i>
                  - The secret used for communication with Cloud Identity and
                  Portal when
                  <strong>Single Sign On</strong>
                  is enabled for the tenant.
                </span>
              </md-field>
              <md-field>
                <label>FRISS Investigations Api Key</label>
                <md-input
                  v-model="
                    tenant.writeToKeyVault.stages[stage]
                      .factsCaseManagementApiKey
                  "
                  type="password"
                ></md-input>
                <span class="md-helper-text">
                  <i>Octopus Variable:</i>
                  <strong>Facts API Key</strong>
                  - The secret used to allow Case Management to communicate with
                  FRISS Investigations.
                </span>
              </md-field>
            </div>
          </md-tab>
        </md-tabs>
      </div>
    </div>
    <div class="md-layout md-gutter md-alignment-center-center">
      <md-button class="md-primary" @click="nextStep">Next</md-button>
    </div>
  </md-step>
</template>

<script>
export default {
  name: "SecretsMigrationStep",
  props: {
    provisioning: {
      type: Object,
      required: true
    },
    stepper: {
      type: Object,
      required: true
    },
    tenant: {
      type: Object,
      required: true
    }
  },
  methods: {
    nextStep() {
      this.$emit("next-step", "keepasstokeyvault", "review");
    }
  }
};
</script>

<style scoped></style>
