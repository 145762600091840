<template>
  <md-dialog
    :md-active.sync="showDialog"
    :md-click-outside-to-close="false"
    :md-close-on-esc="false"
  >
    <md-dialog-title>Delete Tenant</md-dialog-title>
    <md-dialog-content>
      <div>
        <div>
          Are you sure you want to delete tenant
          <strong>{{ tenant.tenantCode }}</strong>
          ?
        </div>
        <br />
        <div>
          <md-field>
            <label>Confirm the deletion by typing the exact tenant code.</label>
            <md-input v-model="confirmationText" type="text"></md-input>
          </md-field>
        </div>
        <div>
          Warning! Deleting a tenant is
          <strong>irreversible.</strong>
          <em>
            * If any user is assigned to more than one tenant, it will only be
            unassigned to that tenant.
          </em>
        </div>
      </div>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button @click="onClose">Close</md-button>
      <md-button
        :disabled="confirmationText !== tenant.tenantCode"
        class="md-button md-danger"
        @click="onDelete"
      >
        Delete
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
export default {
  name: "DeleteDialog",
  data() {
    return {
      confirmationText: ""
    };
  },
  computed: {
    tenant() {
      return this.$tenantStore.state.currentTenant;
    },
    showDialog() {
      return this.$tenantStore.state.showDeleteDialog;
    }
  },
  methods: {
    onClose() {
      this.$tenantStore.closeDeleteDialog();
    },
    async onDelete() {
      try {
        await this.$tenantStore.deleteCurrentTenant();
        this.$notify({
          message: "Tenant deleted",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "success"
        });
        this.$tenantStore.closeDeleteDialog();
      } catch (error) {
        this.$notify({
          message: "Failed to delete tenant in FeatureFlag",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "danger"
        });
      }
    }
  }
};
</script>

<style scoped></style>
