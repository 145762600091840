<template>
  <md-step
    id="provisioning"
    md-label="Provisioning..."
    :md-done.sync="stepper.provisioning"
    :md-editable="!provisioning.ended"
    :md-error="getProvisionStepperIcon"
  >
    <div class="md-layout md-gutter md-alignment-center-center">
      <div class="md-layout-item md-size-50 text-center">
        <h5 class="md-layout-item">Provisioning Tenant...</h5>
        <md-progress-spinner
          :md-diameter="100"
          :md-stroke="10"
          md-mode="indeterminate"
        ></md-progress-spinner>
        <br />
        <h6 class="md-layout-item">
          {{ provisioning.customStatus }}
        </h6>
      </div>
    </div>
  </md-step>
</template>

<script>
export default {
  name: "ProvisioningStep",
  props: {
    provisioning: {
      type: Object,
      required: true
    },
    stepper: {
      type: Object,
      required: true
    }
  },
  computed: {
    getProvisionStepperIcon() {
      return this.provisioning.started &&
        this.provisioning.ended &&
        !this.provisioning.success
        ? "FAILED"
        : null;
    }
  }
};
</script>

<style scoped></style>
