import { directive as vClickOutside } from "vue-clickaway";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive("click-outside", vClickOutside);
    Vue.directive("lowercase", {
      bind(el, _, vnode) {
        el.addEventListener("input", e => {
          e.target.value = e.target.value.toLowerCase();
          vnode.componentInstance.$emit("input", e.target.value.toLowerCase());
        });
      }
    });
  }
};

export default GlobalDirectives;
