var render = function render(){var _vm=this,_c=_vm._self._c;return _c('md-step',{attrs:{"id":"selectrelease","md-label":"What Release?","md-done":_vm.stepper.selectrelease,"md-editable":!_vm.provisioning.started ||
    (_vm.provisioning.started && _vm.provisioning.ended) ||
    _vm.willDeployAnything},on:{"update:mdDone":function($event){return _vm.$set(_vm.stepper, "selectrelease", $event)},"update:md-done":function($event){return _vm.$set(_vm.stepper, "selectrelease", $event)}}},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-100 pad-bottom"},[_c('h5',[_vm._v("Select the Octopus Release's To Deploy:")]),_c('div',[_c('small',[_vm._v(" In order for Provisioning to work, a valid release version must be supplied for each project and each stage. Please copy and paste a valid release version to the respective textbox below. "),_c('br'),_c('strong',[_vm._v(" Keep in mind that Development and Solvinity Octopus MAY have different versions available. ")])])]),(_vm.stepper.options)?_c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('md-tabs',{ref:"releaseTabs",staticStyle:{"padding-top":"25px"}},_vm._l((_vm.tenant.stages),function(stage){return _c('md-tab',{key:stage,attrs:{"md-label":stage}},[_c('div',{staticClass:"md-layout-item"},[(!_vm.tenant.octopusDeployments[_vm.OCTOPUS_PROJECTS.FCM].skip)?_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('md-field',{class:{ 'md-invalid': errors.length > 0 }},[_c('label',[_vm._v(" "+_vm._s(_vm.OCTOPUS_PROJECTS.FCM)+" ")]),_c('md-input',{model:{value:(
                        _vm.tenant.octopusDeployments[_vm.OCTOPUS_PROJECTS.FCM]
                          .selectedVersion[stage]
                      ),callback:function ($$v) {_vm.$set(_vm.tenant.octopusDeployments[_vm.OCTOPUS_PROJECTS.FCM]
                          .selectedVersion, stage, $$v)},expression:"\n                        tenant.octopusDeployments[OCTOPUS_PROJECTS.FCM]\n                          .selectedVersion[stage]\n                      "}}),_c('span',{staticClass:"md-helper-text"},[_c('i',[_vm._v("Example: 2021.12.0-build2116000")])]),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}):_vm._e(),(
                    !_vm.tenant.octopusDeployments[_vm.OCTOPUS_PROJECTS.AUDIT].skip
                  )?_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('md-field',{class:{ 'md-invalid': errors.length > 0 }},[_c('label',[_vm._v(" "+_vm._s(_vm.OCTOPUS_PROJECTS.AUDIT)+" ")]),_c('md-input',{model:{value:(
                        _vm.tenant.octopusDeployments[_vm.OCTOPUS_PROJECTS.AUDIT]
                          .selectedVersion[stage]
                      ),callback:function ($$v) {_vm.$set(_vm.tenant.octopusDeployments[_vm.OCTOPUS_PROJECTS.AUDIT]
                          .selectedVersion, stage, $$v)},expression:"\n                        tenant.octopusDeployments[OCTOPUS_PROJECTS.AUDIT]\n                          .selectedVersion[stage]\n                      "}}),_c('span',{staticClass:"md-helper-text"},[_c('i',[_vm._v("Example: 2021.12.0-build2116000")])]),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}):_vm._e(),(
                    !_vm.tenant.octopusDeployments[_vm.OCTOPUS_PROJECTS.TENANT_WEB]
                      .skip
                  )?_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('md-field',{class:{ 'md-invalid': errors.length > 0 }},[_c('label',[_vm._v(" "+_vm._s(_vm.OCTOPUS_PROJECTS.TENANT_WEB)+" ")]),_c('md-input',{model:{value:(
                        _vm.tenant.octopusDeployments[_vm.OCTOPUS_PROJECTS.TENANT_WEB]
                          .selectedVersion[stage]
                      ),callback:function ($$v) {_vm.$set(_vm.tenant.octopusDeployments[_vm.OCTOPUS_PROJECTS.TENANT_WEB]
                          .selectedVersion, stage, $$v)},expression:"\n                        tenant.octopusDeployments[OCTOPUS_PROJECTS.TENANT_WEB]\n                          .selectedVersion[stage]\n                      "}}),_c('span',{staticClass:"md-helper-text"},[_c('i',[_vm._v("Example: 2021.12.0-build2116000")])]),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}):_vm._e(),(
                    !_vm.tenant.octopusDeployments[_vm.OCTOPUS_PROJECTS.SIDE_BY_SIDE]
                      .skip
                  )?_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('md-field',{class:{ 'md-invalid': errors.length > 0 }},[_c('label',[_vm._v(" "+_vm._s(_vm.OCTOPUS_PROJECTS.SIDE_BY_SIDE)+" ")]),_c('md-input',{model:{value:(
                        _vm.tenant.octopusDeployments[
                          _vm.OCTOPUS_PROJECTS.SIDE_BY_SIDE
                        ].selectedVersion[stage]
                      ),callback:function ($$v) {_vm.$set(_vm.tenant.octopusDeployments[
                          _vm.OCTOPUS_PROJECTS.SIDE_BY_SIDE
                        ].selectedVersion, stage, $$v)},expression:"\n                        tenant.octopusDeployments[\n                          OCTOPUS_PROJECTS.SIDE_BY_SIDE\n                        ].selectedVersion[stage]\n                      "}}),_c('span',{staticClass:"md-helper-text"},[_c('i',[_vm._v("Example: 2021.12.0-build2116000")])]),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}):_vm._e()],1)])}),1),_c('div',{staticClass:"md-layout md-gutter md-alignment-center-center"},[_c('md-button',{staticClass:"md-primary",attrs:{"disabled":invalid},on:{"click":_vm.onSubmit}},[_vm._v(" Next ")])],1)],1)]}}],null,false,3696293282)})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }